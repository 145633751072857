<template>
<div>
    <v-toolbar class="primary" dark>
        <v-container>
            <v-row>
                <v-toolbar-title class="mt-3">Agency Profile</v-toolbar-title>
            </v-row>
        </v-container>
    </v-toolbar>
    <v-progress-linear v-if="loading" class="amber" indeterminate></v-progress-linear>
    <v-container class="maxWidth">
        <v-card>
            <v-toolbar dense class="elevation-0 grey lighten-3">
                <v-toolbar-title>Details</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col md="3" class="text-center">
                        <img :src="photoURL" class="borderProfile100">
                    </v-col>
                    <v-col md="9">
                        <div class="display-1 font-weight-bold">{{displayName}}</div>
                        <div class="">{{address}}</div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-toolbar dense class="elevation-0 grey lighten-3">
                <v-toolbar-title>Listings</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-row class="text-center" v-if="id != ''">
                    <v-col md="4"><Stat :id="id" :type="'Agency'" :status="'Active'" :show="'card'"/></v-col>
                    <v-col md="4"><Stat :id="id" :type="'Agency'" :status="'Sold'" :show="'card'"/></v-col>
                    <v-col md="4"><Stat :id="id" :type="'Agency'" :status="'Off'" :show="'card'"/></v-col>
                </v-row>
            </v-card-text>
            <v-toolbar dense class="elevation-0 grey lighten-3">
                <v-toolbar-title>Agents</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col md="3" v-for="a in agents" :key="a.id">
                        <v-card @click="showAgent(a.id)" outlined class="elevation-0">
                            <v-card-text class="text-center">
                                <img :src="getImageURL(a.data.photoURL)" class="borderProfile100">
                                <div class="font-weight-bold mt-4">{{a.data.displayName}}</div>
                                <div>{{a.data.userType}}</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</div>
</template>
<script>
import firebase from 'firebase'
import Functions from '../Functions'
export default {
    name: 'AProfile',
    data: () => ({
        displayName: '',
        address: '',
        photoURL: '',
        loading: true,
        id: '',
        agents: []
    }),
    mounted() {
//        console.log(this.$route.params.id)
        if (this.$route.params.id.startsWith('Agency')) {
            this.id = this.$route.params.id.replace('Agency:', '')
            this.getAgencyProfile()
            this.getAgencyAgents()
        } else {
            
        }
    },
    methods: {
        getAgencyProfile() {
            firebase.firestore().collection('Agencies').doc(this.id).get()
            .then((doc) => {
                this.displayName = doc.data().name
                this.address = doc.data().address
                this.photoURL = Functions.GetImageURL(doc.data().profileurl)
                this.loading = false
            }).catch((err) => {
                console.log(err)
            })
        },
        getAgencyAgents() {
            let agents = []
            firebase.firestore().collection('PublicProfile')
            .where('userType', 'in', ['Seller', 'Agency Owner'])
            .where('agency.id', '==', this.id)
            .get()
            .then((docs) => {
                docs.forEach(doc => {
                    if (doc.data().status == null || doc.data().status == 'Active')
                    agents.push({id: doc.id, data: doc.data()})
                })
                this.agents = agents
            }).catch((err) => {
                console.log(err)
            })
        },
        getImageURL(url) {
            return Functions.GetImageURL(url)
        },
        showAgent(id) {
            this.$router.push(`/profile/${id}`)
        }
    }
}
</script>