<template>
    <v-avatar @click="Clicked" :size="size" :tile="tile">
        <img :src="url">
    </v-avatar>            
</template>
<script>
export default {
    name: 'Avatar',
    props: ['url', 'path', 'size', 'tile'],
    data: () => ({
    }),
    methods: {
        Clicked() {
            if (this.path != "") {
                this.$router.push(this.path)
            }
        }
    }
}
</script>