<template>
    <v-container>
        <div class="text-left">
            <h4 class="mt-5">User Terms</h4>
            <ul>
                <li>The User hereby warrants to Amaraat that it is at least eighteen years of age and legally able to enter into contracts.</li>
                <li>While registering on Amaraat App, the User must provide true, accurate, current and complete Registration Details. The User must also update any further changes in these details, before using the App for further services in the future.</li>
                <li>The User’s Registration Details and data relating to its use of our App will be recorded by Amaraat, but this information shall not be disclosed to third parties (otherwise than on an aggregated, anonymous basis).</li>
                <li>The User hereby authorises Amaraat to use any information which it submits to this Website to inform the User of special offers, occasional third party offers and for other marketing and related purposes. Amaraat will not use User data for any other purposes than as set out in this Agreement except that Amaraat may disclose this data if compelled to do so by law, or at the request of a law enforcement agency or governmental authority.</li>
                <li>User agrees not to use the Service other than for its own personal use or as an agent listing property for sale and to rent.</li>
                <li>User agrees not to publish any Material that may encourage a breach of any relevant laws or regulations.</li>
                <li>Amaraat permits the User to post Material on the Website in accordance with Amaraat’s procedures provided that Material is not illegal, obscene, abusive, threatening, defamatory or otherwise objectionable to Amaraat.</li>
                <li>Amaraat reserves the discretion to withdraw any listing from our App, without prior notice and to refuse any Material posted by a User.</li>
                <li>Amaraat reserves the right to suspend or terminate a User’s account where, in its absolute discretion, it deems such suspension appropriate. In the event of such suspension or termination, Amaraat will notify the User by email and the User must not seek to re-register either directly or indirectly through a related entity. In the event of cancellation, the User shall pay any outstanding invoices relating to the Service agreement within 14 working days.</li>
                <li>Amaraat owns all Intellectual Property Rights of its App, including without limitation, the design, text, graphics, the selection and arrangement thereof.</li>
            </ul>
            <h4 class="mt-5">Limitation of Liability</h4>
            <ul>
                <li>Neither Amaraat nor any of its affiliated entities will be liable for any indirect loss, consequential loss, loss of profits, revenue, data or goodwill howsoever arising suffered by any User arising in any way in connection with this Agreement or for any liability of a User to any third party.</li>
                <li>Amaraat shall not be liable for any interruption to the App, whether intentional or otherwise.</li>
                <li>Whilst Amaraat will make all reasonable attempts to exclude viruses from its App, it cannot ensure such exclusion and no liability is accepted for viruses. Thus, the User is recommended to take all appropriate safeguards before using information or any Material from our App.</li>
                <li>None of the clauses herein shall apply so as to restrict liability for death or personal injury resulting from the negligence of Amaraat or its appointed agents.</li>
            </ul>
            <h4 class="mt-5">Warranties and Indemnity</h4>
            <ul>
                <li>Amaraat does not represent or warrant that the information accessible via the App is accurate, complete or current. Amaraat has no liability whatsoever in respect of any use which the User makes of such information.</li>
                <li>Material has not been written to meet the individual requirements of the User and it is the User’s sole responsibility to satisfy itself prior to entering into any transaction or decision that the Material is suitable for its purposes.</li>
                <li>The User hereby agrees to indemnify Amaraat against all liabilities, claims and expenses that may arise from any breach of this Agreement by the User.</li>
            </ul>
            <h4 class="mt-5">General</h4>
            <ul>
                <li>Amaraat reserves the right to assign or subcontract any or all of its rights and obligations under this Agreement.</li>
                <li>Amaraat shall not be liable for any loss suffered by the other party or be deemed to be in default for any delays or failures in performance hereunder resulting from acts or causes beyond its reasonable control or from any acts of God, acts or regulations of any governmental or supra-national authority.</li>
                <li>This Agreement shall be governed by and constructed in accordance with the laws of Pakistan and the User submits to the exclusive jurisdiction of the Courts of Lahore, Pakistan. Amaraat can take action against the User in any relevant jurisdiction to enforce its rights.</li>
            </ul>
        </div>
    </v-container>
</template>
<script>
export default {
    data: () => ({
    })
}
</script>