import Data from './Data'
import firebase from 'firebase'
export default {
    type() {
        console.log("this is type")
    },
    user: {
        UserType: "Buyer",
        displayName: "Buyer",
        email: "",
        uid: "",
        gender: "Not Specified",
        phoneNumber: "",
        photoURL: "https://imlaak.blob.core.windows.net/image/baseline_account_circle_black_48dp.png",
        agency: null
    },
    GetTitle(path) {
        switch(path) {
            case '/':
                return 'Amaraat'
                break;
            case '/profile':
                return 'Profile'
                break;
            case '/login':
                return 'Login'
                break;
            default:
                return 'Amaraat'
        }
    },
    GetSocieties(city) {
        let Societies = Data.Societies.filter(s => s.CityCode == city)
        return Societies
    },
    GetPhases(society) {
        let Phases = Data.Phases.filter(s => s.SocietyCode == society)
        return Phases
    },
    GetBlocks(phase, type) {
        let Blocks = []
        if (type == "All") {
            Blocks = Data.Blocks.filter(s => s.PhaseDBCode == phase)
        }
        else {
            Blocks = Data.Blocks.filter(s => s.PhaseDBCode == phase && s.Type == type)
        }
        return Blocks
    },
    GetBlockType(block) {
        let result = "Residential"
        result = Data.Blocks.filter(s => s.BlockDBCode == block)
        return result[0].Type
    },
    GetCityName(city) {
        let result = 'Unknown'
        let rqd = Data.Cities.filter(c => c.CityCode == city)
        if (rqd.length > 0) {
            result = rqd[0].CityName
        }
        return result
    },
    GetSocietyName(city, society) {
        let result = 'Unknown'
        let rqd = Data.Societies.filter(s => s.CityCode == city && s.SocietyCode == society)
        if (rqd.length > 0) {
            result = rqd[0].SocietyName
        }
        return result
    },
    GetPhaseName(city, society, phase) {
        let result = 'Unknown'
        let rqd = Data.Phases.filter(p => p.CityCode == city && p.SocietyCode == society && p.PhaseDBCode == phase)
        if (rqd.length > 0) {
            result = rqd[0].PhaseName
        }
        return result
    },
    GetBlockName(city, society, phase, blocks, type) {
        let result = ''
        for (let i = 0; i < blocks.length; i++) { 
            let rqd = []
            if (type == 'Plot' || type == 'All') {
                rqd = Data.Blocks.filter(b => b.CityCode == city && b.SocietyCode == society && b.PhaseDBCode == phase && b.BlockDBCode == blocks[i])
            } else {
                rqd = Data.Mouzas.filter(m => m.CityCode == city && m.SocietyCode == society && m.PhaseDBCode == phase && m.MouzaDBCode == blocks[i])
            }
            if (rqd.length > 0) {
                for (let j = 0; j < rqd.length; j++) {
                    if (result != '') {
                        result += ', '
                    }
                    if (type == 'Plot' || type == 'All') {
                        result += rqd[j].BlockName
                    } else {
                        result += rqd[j].MouzaName
                    }
                }
            }
        }
        return result
    },
    GetImageURL(url) {
        let u = url.replace("https://imlaak.blob.core.windows.net/image/", "")
        if (url.startsWith('https://firebasestorage.googleapis')) {
            return url
        }
        return "https://imlaak.blob.core.windows.net/image/" + u
    },
    PriceInDefaultUnit(price) {
        let p = Number(price)
        p = p/10000000
        return p.toFixed(2) + " Cr"
    },
    AreaInDefaultUnit(area) {
        let Area = 0;
        Area = Number(area) / 272.25;
        return Area.toFixed(2) + " Marla";
    },
    CombineArea(kanal, marla) {
        let totalArea = 0
        let k = Number(kanal)
        let m = Number(marla)
        if (!isNaN(k) && !isNaN(m)) {
            let k1 = k * 20 * 272.25
            let m1 = m * 272.25
            totalArea = k1 + m1
        }
        return totalArea
    },
    CombinePrice(crore, lakh) {
        let price = 0
        let c = Number(crore)
        let l = Number(lakh)
        if (!isNaN(c) && !isNaN(l)) {
            let c1 = c * 10000000
            let l1 = l * 100000
            price = c1 + l1
        }
        return price
    },
    CheckAddress(address) {
        let result = true
        let check = address.split('-')
        if (check.length != 6) {
            result = false
        } else {
            check.forEach((add) => {
                if (add == '') {
                    result = false
                }
            })
        }
        return result
    },
    ConvertStringToAddress(a) {
        let address = {country: 'PK', state: 'PJ', city: '', society: '', phase: '', block: ''}
        let aa = a.split('-')
        if (aa.length >= 6) {
            address.city = aa[2]
            address.society = aa[3]
            address.phase = aa[4]
            address.block = aa[5]
        }
        return address
    },
    async GetListings(type, id) {
        let data = []
        if (type == 'agent') {
            await firebase.firestore().collection('Search')
            .where('status', '==' , `Active`)
            .where('search', 'array-contains' , `Agent:${id}`)
            .orderBy('timestamp', 'desc').get()
            .then((docs) => {
                let c = 0;
                docs.forEach(doc => {
                    let m = doc.data().marker != null ? {lat: doc.data().marker.latitude, lng: doc.data().marker.longitude} : null
                    data.push({
                        id: doc.id, 
                        data: doc.data(), 
                        index: data.length + 1, 
                        marker: m
                    })
                })
            }).catch((err) => {
                console.log(err)
            })
        } else if (type == 'mysaved') {
            await firebase.firestore().collection('SavedListings')
            .where('status', '==' , `Active`)
            .where('user', '==' , `${id}`)
            .orderBy('timestamp', 'desc').get()
            .then((docs) => {
                let c = 0;
                docs.forEach(doc => {
                    let m = doc.data().marker != null ? {lat: doc.data().marker.latitude, lng: doc.data().marker.longitude} : null
                    data.push({
                        id: doc.id, 
                        data: doc.data(), 
                        index: data.length + 1, 
                        marker: m
                    })
                })
            }).catch((err) => {
                console.log(err)
            })
        }
        return data
    },
    ConvertFeaturesToString(features) {
        let result = '00000';
        let corner = '0';
        let fp = '0';
        let dp = '0';
        let pole = '0';
        let possession = '0';
        if (features.corner) {
          corner = '1';
        }
        if (features.dp) {
          dp = '1';
        }
        if (features.facingPark) {
          fp = '1';
        }
        if (features.pole) {
          pole = '1';
        }
        if (features.possession) {
          possession = '1';
        }
        result = `${corner}${dp}${fp}${pole}${possession}`
        return result;
      }
}