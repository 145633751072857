<template>
<div>
    <v-container>
        <v-row class="py-3">
            <h3>{{title}}</h3>
        </v-row>
        <GmapMap :center="mapCenter" :zoom="12" map-type-id="satellite" class="map" style="height: 240px;">
            <GmapMarker
                :key="index"
                v-for="(m, index) in MapMarkers"
                :position="m.position"
                :clickable="true"
                :draggable="false"
                @click="center=m.position"/>
        </GmapMap>
        <v-row>
            <v-col md="4" sm="12" v-for="L in listings" :key="L.id">
                <NewListingCard :item="L" :index="L.index"/>
            </v-col>
        </v-row>
    </v-container>
</div>

<!--
<div class="fill-height">
    <div class="fill-height pa-2">
        <GmapMap :center="mapCenter" :zoom="15" map-type-id="satellite" class="map">
            <GmapMarker
                :key="index"
                v-for="(m, index) in MapMarkers"
                :position="m.position"
                :clickable="true"
                :draggable="false"
                @click="center=m.position"/>
        </GmapMap>
    </div>
    <v-navigation-drawer right app floating clipped width="500" min-width="300">
        <v-list style="width: 100%;">
            <Listing v-for="L in listings" :key="L.id" :item="L" :index="L.index"/>
        </v-list>
    </v-navigation-drawer>
</div>
-->
</template>
<script>
export default {
    name: 'SearchResults',
    data: () => ({
        title: 'Search Results',
        mapCenter: {lat:31.595228, lng:74.302232},
        listings: []
    }),
    mounted() {
        let marker = null
        this.$store.dispatch('ClearMarkers', [])
        this.listings = this.$store.state.listings
        this.listings.forEach(listing => {
            let m = listing.marker != null ? {lat: listing.marker.lat, lng: listing.marker.lng} : null
            if (marker == null) {
                marker = m
                this.mapCenter = marker
            }
        })
    },
    computed: {
        MapMarkers() {
            return this.$store.state.markers
        }
    }
}
</script>