<template>
<div>
    <v-toolbar class="primary" dark>
        <v-container>
            <v-row>
                <v-toolbar-title class="mt-3">My Requirements</v-toolbar-title>
                <v-spacer/>
                <NewSearch type="invertedicon" searchFor="requirements"/>
                <v-btn icon dark @click="menuClicked('/addrequirement')"><v-icon>mdi-plus</v-icon></v-btn>
            </v-row>
        </v-container>
    </v-toolbar>
    <v-progress-linear v-if="loading" class="amber" indeterminate></v-progress-linear>
    <v-container class="maxWidth">
        <v-tabs fixed-tabs v-model="tab">
            <v-tab>Private</v-tab>
            <v-tab>Agency/Agent</v-tab>
            <v-tab>Public</v-tab>
            <v-tab>Shared with Me</v-tab>
        </v-tabs>
        <Requirement v-for="r in requirements" :key="r.id" :type="type" :item='r'/>
    </v-container>
    <v-btn fab fixed bottom right color="amber" @click.stop=""><v-icon>mdi-plus</v-icon></v-btn>
</div>
</template>
<script>
import firebase from 'firebase'
export default {
    name: 'MyRequirements',
    data: () => ({
        tab: 0,
        loading: false,
        requirements: [],
        collection: 'Wishes',
        type: 'mine'
    }),
    watch: {
        tab(newVal, oldVal) {
            if (newVal == 0) {
                this.type = 'mine'
                this.collection = 'Wishes'
                this.getRequirements()
            } else if (newVal == 1) {
                this.type = 'mine'
                this.collection = 'SharedWishes'
                this.getRequirements()
            } else if (newVal == 2) {
                this.type = 'mine'
                this.collection = 'PublicWishes'
                this.getRequirements()
            } else if (newVal == 3) {
                this.type = 'public'
                this.getSharedRequirements()
            }
        }
    },
    mounted() {
        this.getRequirements()
    },
    methods: {
        address(address) {
            console.log(address)
        },
        getRequirements() {
            let user = firebase.auth().currentUser
            if (user != null) {
                this.loading = true
                let data = []
                firebase.firestore().collection(this.collection)
                .orderBy('timestamp', 'desc')
                .where('userID', '==', user.uid)
                .get()
                .then((docs) => {
                    docs.forEach(doc => {
                        data.push({id: doc.id, data: doc.data(), index: data.length + 1})
                    })
                    this.requirements = data
                    this.loading = false
                }).catch((err) => {
                    console.log(err)
                })
            }
        },
        getSharedRequirements() {
            let user = firebase.auth().currentUser
            if (user != null && this.$store.state.user.agency != null) {
                this.loading = true
                let data = []
                firebase.firestore().collection('SharedWishes')
                .orderBy('timestamp', 'desc')
                .where('agencyID', '==', this.$store.state.user.agency.id)
                .get()
                .then((docs) => {
                    docs.forEach(doc => {
                        if (doc.data().userID != user.uid) {
                            data.push({id: doc.id, data: doc.data(), index: data.length + 1})
                        }
                    })
                    this.requirements = data
                    this.loading = false
                }).catch((err) => {
                    console.log(err)
                })
            }
        },
        menuClicked(path) {
            if (path == 'mine') {

            } else {
                this.$router.push(path)
            }
        }
    }

}
</script>