<template>
    <v-container class="mt-5">
        <carousel-3d :height="500" :autoplay="true" :autoplay-timeout="5000" @after-slide-change="onAfterSlideChange">
            <slide v-for="(slide, i) in slides" :index="i" :key="i" class="transparent elevation-10">
                <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
                    <v-card class="text-center pa-5 grey lighten-2">
                        <p class="text-center">{{slide.title}}</p>
                        <v-img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="slide.src" class="imageborder"/>
                    </v-card>
                </template>
            </slide>
        </carousel-3d>
        <p class="text-center">{{CurrentDesc}}</p>
    </v-container>
</template>

<script>
export default {
    name: 'WhyAmaraat',
    data: () => ({
        slides: [
            {title: "What's Hot", src: '../img/directions.png', desc: "Find out what's hot ? What are the latest plots/files in the market for sale"},
            {title: "Near You", src: '../img/1.png', desc: "Find out what's for sale near you"},
            {title: "Chats", src: '../img/1.png', desc: "Get in touch with the seller directly."},
            {title: "Notifications", src: '../img/1.png', desc: "Stay up to date, with offers, wishlists, saved searches, plot/file updates and so much more."},
            {title: "Wishlists", src: '../img/directions.png', desc: "Create a wish list, keep it personal to get notified, or share it to let seller reach you"},
            {title: "Get directions", src: '../img/directions.png', desc: "Get directions to any plot."},
            {title: "Make an offer", src: '../img/1.png', desc: "Make an offer to the seller directly"},
            {title: "Digital Maps", src: '../img/1.png', desc: "Mark and share map files from within the app"},
            {title: "Find Agents/Agencies", src: '../img/1.png', desc: "Search for any agency or agent by their name"},
            {title: "Search for plots/files", src: '../img/directions.png', desc: "Search for plot/file in any city/society/phase or block"},
        ],
        CurrentDesc: ''
    }),
    methods: {
        onAfterSlideChange(index) {
            this.CurrentDesc = this.slides[index].desc
        }
    }
}
</script>