<template>
<div>
    <v-app-bar app clipped-right>
        <div class="logo" @click="MenuClicked('/')"><img src="img/icons/favicon-32x32.png" height="32" width="32" class="mt-2"/> <div class="logoTitle">Amaraat</div></div>
        <v-spacer/>
        <v-btn text @click="MenuClicked('/requirements')" v-if="!$vuetify.breakpoint.mobile">Requirements</v-btn>
        <NewSearch type="text" searchFor="listings" v-if="!$vuetify.breakpoint.mobile"/>
        <NewSearch type="icon" searchFor="listings" v-else/>
        <!--
        <v-btn class="primary mr-2" rounded dark @click="MenuClicked('/newlisting')">Sell</v-btn>
        -->
        <v-btn class="ml-1" color="primary" dark rounded @click="MenuClicked('/buy')" v-if="!$vuetify.breakpoint.mobile">Buy</v-btn>
        <seller-btn v-on:seller="seller" class="mx-1"/>
        <v-avatar @click="updateDrawer" size="36" class="elevation-3 logo">
            <img v-if="signedIn" :src="user.photoURL" alt="Name">
            <v-icon v-else>mdi-account-circle</v-icon>
        </v-avatar>
        <!--
        <template v-slot:extension>
            <v-container>
                <NewSearch type="home" searchFor="listings"/>
            </v-container>
        </template>
        -->
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" color="primary" app dark temporary right>
        <v-list dense nav class="py-0">
            <v-list-item two-line class="pa-0" link @click="Login">
                <v-list-item-avatar>
                    <img :src="user.photoURL">
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{user.displayName}}</v-list-item-title>
                    <v-list-item-subtitle>{{user.userType}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <template v-for="(item, i) in items">
                <v-subheader v-if="item.path == null" :key="i">{{item.title}}</v-subheader>
                <v-list-item v-else :key="item.title" link @click="MenuClicked(item.path)">
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
        <template v-slot:append> 
            <v-list-item link @click="logOut">
                <v-list-item-icon>
                    <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-navigation-drawer>
</div>
</template>
<script>
import firebase from 'firebase'
import Functions from '../Functions'
import NewSearch from './NewSearch.vue'
import Data from '../Data'
export default {
  components: { NewSearch },
    name: 'AppHeader',
    data: () => ({
        signedIn: false,
        drawer: false,
        title: 'Amaraat',
        backBtn: false,
        items: [
            { title: 'My Amaraat', },
            { title: 'Daily News Alerts', icon: 'mdi-calendar', path: '/', auth: false },
            { title: 'Saved Properties', icon: 'mdi-image', path: '/mysaved', auth: false },
            { title: 'My Requirements', icon: 'mdi-help-box', path: '/myrequirements', auth: false },
            { title: 'Find Agents/Agency', icon: 'mdi-help-box', path: '/findagencyagent', auth: false },
            { title: 'Seller', },
//            { title: 'Offers', icon: 'mdi-calendar', path: '/', auth: false },
            { title: 'My Listings', icon: 'mdi-calendar', path: '/mylistings', auth: false },
            { title: 'Agency Owner', },
            { title: 'Agency Profile', icon: 'mdi-calendar', path: '/myagency', auth: false },
            { title: 'My Agents in Agency', icon: 'mdi-calendar', path: '/myagents', auth: false },
        ],
        currentPath: '/'
    }),
    mounted() {
        this.currentPath = this.$router.currentRoute.path
        this.title = Functions.GetTitle(this.currentPath)
        if (this.currentPath != "/") {
            this.backBtn = true
        }
        else {
            this.backBtn = false
        }
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.signedIn = true
                this.$store.dispatch('User', user)
            } else {
                this.$store.dispatch('User', null)
                this.signedIn = false
            }
        })
    },
    watch: {
        $route(newVal, oldVal) {
            this.currentPath = newVal.path
        },
        currentPath(newVal, oldVal) {
            this.title = Functions.GetTitle(newVal)
            if (newVal == "/") {
                this.backBtn = false
            }
            else {
                this.backBtn = true
            }
        }
    },
    methods: {
        Back() {
            if (this.$router.currentRoute.path != "/") {
                this.$router.back()
            }
        },
        seller() {
            console.log(this.user.userType)
            if (this.user.userType == 'Seller' || this.user.userType == 'Agency Owner') {
                this.$router.push('/newlisting')
            }
        },
        updateDrawer() {
            if (this.signedIn) {
                this.drawer = !this.drawer
            } else {
                this.$router.push('/login')
            }

        },
        async MenuClicked(path){
            let user = firebase.auth().currentUser
            if (path == "/myagency") {
                if (user) {
                    this.drawer = false
                    let agencyID = this.$store.state.user.agency.id
                    this.$router.push(`/aprofile/Agency:${agencyID}`)
                }
            } else if (path == "/mylistings") {
                if (user) {
                    let listings = await Functions.GetListings('agent', user.uid)
                    if (listings.length > 0) {
                        this.drawer = false
                        this.$store.dispatch('ClearMarkers')
                        this.$store.dispatch('Listings', listings)
                        if (this.$router.currentRoute.fullPath != '/searchresults') {
                            this.$router.push('/searchresults')
                        }
                    } else {
                        this.$store.dispatch('SnackBar', Data.ZeroListingsSnackBar)
                    }
                }
            } else if (path == "/mysaved") {
                if (user) {
                    let listings = await Functions.GetListings('mysaved', user.uid)
                    if (listings.length > 0) {
                        this.drawer = false
                        this.$store.dispatch('ClearMarkers')
                        this.$store.dispatch('Listings', listings)
                        if (this.$router.currentRoute.fullPath != '/searchresults') {
                            this.$router.push('/searchresults')
                        }
                    } else {
                        this.$store.dispatch('SnackBar', Data.ZeroListingsSnackBar)
                    }
                }
            } else {
                this.drawer = false
                this.$router.push(path)
            }
        },
        Login() {
            let user = firebase.auth().currentUser
            if (!this.signedIn) {
                this.$router.push('/login')
            }
            else {
                this.$router.push(`/profile/${user.uid}`)
            }
        },
        logOut() {
            firebase.auth().signOut()
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    }
}
</script>