import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Carousel3d from 'vue-carousel-3d'

import AppHeader from './components/AppHeader'
import Address from './components/Address'
import AgencyAgent from './components/AgencyAgent'
import Avatar from './components/Avatar'
import Features from './components/Features'
import Listing from './components/Listing'
import NewListingCard from './components/NewListingCard'
import NewSearch from './components/NewSearch'
import AgencyCard from './components/AgencyCard'
import Stat from './components/Stat'
import Location from './components/Location'
import InputPair from './components/InputPair'
import FeaturesInput from './components/FeaturesInput'
import Requirement from './components/Requirement'
import PhoneNumberReveal from './components/PhoneNumberReveal'
import SellerBtn from './components/SellerBtn'
import WhyAmaraat from './components/WhyAmaraat'

import vuetify from '@/plugins/vuetify'
import firebase from 'firebase'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCliiiFMRflgPMgUoSMSCyOhCr0xfnE0LY',
    libraries: 'places',
    useBetaRenderer: false
  },
  installComponents: true
})
Vue.use(Carousel3d)

let firebaseConfig = {
  apiKey: "AIzaSyCM4ruk8DI8rlkyfYr8cSzrVWXxjsHxnWQ",
  authDomain: "amaraatapp-cbf37.firebaseapp.com",
  databaseURL: "https://amaraatapp-cbf37.firebaseio.com",
  projectId: "amaraatapp-cbf37",
  storageBucket: "amaraatapp-cbf37.appspot.com",
  messagingSenderId: "435021638419",
  appId: "1:435021638419:web:e5e673080d54157cb108e6",
  measurementId: "G-5LRYFXT050"
}
firebase.initializeApp(firebaseConfig)

Vue.config.productionTip = false

Vue.component('AppHeader', AppHeader)
Vue.component('Address', Address)
Vue.component('AgencyAgent', AgencyAgent)
Vue.component('Avatar', Avatar)
Vue.component('Features', Features)
Vue.component('Listing', Listing)
Vue.component('NewListingCard', NewListingCard)
Vue.component('NewSearch', NewSearch)
Vue.component('AgencyCard', AgencyCard)
Vue.component('Stat', Stat)
Vue.component('Location', Location)
Vue.component('InputPair', InputPair)
Vue.component('FeaturesInput', FeaturesInput)
Vue.component('Requirement', Requirement)
Vue.component('PhoneNumberReveal', PhoneNumberReveal)
Vue.component('SellerBtn', SellerBtn)
Vue.component('WhyAmaraat', WhyAmaraat)


new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
