<template>
    <v-container>
        <v-breadcrumbs :items="bitems"></v-breadcrumbs>
        <v-tabs fixed-tabs dark background-color="#3B5998" v-model="SelectedTab">
            <v-tab>
                All
            </v-tab>
            <v-tab>
                Agents FAQs
            </v-tab>
            <v-tab>
                Choosing a Property
            </v-tab>
            <v-tab>
                User FAQs
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="SelectedTab">
            <v-tab-item class="pa-5">
                <v-expansion-panels focusable>
                    <v-expansion-panel v-for="(P, I) in AllFAQs" :key="I">
                        <v-expansion-panel-header>{{P.Q}}</v-expansion-panel-header>
                        <v-expansion-panel-content class="text-left pa-5">{{P.A}}</v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-tab-item>
            <v-tab-item class="pa-5">
                <v-expansion-panels focusable>
                    <v-expansion-panel v-for="(P, I) in AgentsFAQs" :key="I">
                        <v-expansion-panel-header>{{P.Q}}</v-expansion-panel-header>
                        <v-expansion-panel-content class="text-left pa-5">{{P.A}}</v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-tab-item>
            <v-tab-item class="pa-5">
                <v-expansion-panels focusable>
                    <v-expansion-panel v-for="(P, I) in ChoosingFAQs" :key="I">
                        <v-expansion-panel-header>{{P.Q}}</v-expansion-panel-header>
                        <v-expansion-panel-content class="text-left pa-5">{{P.A}}</v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-tab-item>
            <v-tab-item class="pa-5">
                <v-expansion-panels focusable>
                    <v-expansion-panel v-for="(P, I) in UserFAQs" :key="I">
                        <v-expansion-panel-header>{{P.Q}}</v-expansion-panel-header>
                        <v-expansion-panel-content class="text-left pa-5">{{P.A}}</v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>
<script>
export default {
    data: () => ({
        bitems: [{text: 'Home', disabled: false, href: '/'}, {text: 'FAQ', disabled: true, href: '/faqs'}],
        SelectedTab: null,
        AllFAQs: [
            {Q: 'Can I list the same plot that another agent has already listed?', A: 'Yes, one can but these plots will show under the single category of ‘Multiple agents’ listings. And a sorted list of different agents (according to their post timing and their ask price) dealing with that specific plot will be clearly visible to our user.'},
            {Q: 'Do I need to refresh my listings?', A: 'Yes, after 30 days each listing needs to be refreshed, or otherwise, it will be removed automatically, leaving only the fresh and authentic information available to our customers.'},
            {Q: 'Are there any hot listings? How can I prioritize my listings?', A: 'Each listing is equally valuable for us, that is why there is no such thing like ‘Hot Listings’ in our App, for which one normally pays extra money. Every Listing is ‘HOT’ at our platform.'},
            {Q: 'Can I choose my listing package?', A: 'Our packages are based on the number staff being employed by a specific agency, and our strictly prescribed by the sales agents on their visit to your office.'},
            {Q: 'How can I increase my listings quota?', A: 'We believe in providing quality data only that is why increasing any agency’s listings quota is completely onto Amaraat’s qualified staff. Our staff will independently evaluate the case for each agency, based on their size and performance and then will make this decision accordingly.'},
            {Q: 'How long is the expiry of a listing?', A: 'Catering to the problem of old and zombie ads, at the end of each month, each listing will be refreshed and if not, it will be removed automatically.'},
            {Q: 'How to edit a listing?', A: 'Just click on the edit button, make changes and save them by clicking on the submit button.'},
            {Q: 'How can I make an offer on the app?', A: 'How can I make an offer on the app? By clicking on the interested plot from the search list, one can clearly see the ‘make offer’ option in our app. In this way, you can easily offer the price of your interested plots to their authentic sellers by simply texting them in a separate chat box within the App'},
            {Q: 'Will I receive any information on my saved preferences?', A: 'Whenever a similar listing or a plot will be advertised, our users will be instantly notified on their App.'},
            {Q: 'How can an agency create an account on Amaraat App?', A: 'After downloading Amaraat App, the users will be initially asked to provide a verification code being sent on their Email IDs, before they can further register themselves with their unique Passwords. For an agency, all the profile details, with a randomly generated license number is required, which will be further processed and accepted through Amaraat portal before making their account active.'},
            {Q: 'How can an agent create an account on Amaraat App?', A: 'After downloading Amaraat App, the users will be initially asked to provide a verification code being sent on their phone numbers, before they can further register themselves with unique IDs and Passwords. Agents are then required to provide the uniquely allotted agency license number for their requests to be processed further. Once the registered agency will accept their requests, only then they will be made eligible to start selling plots.'},
            {Q: 'Can I modify my research for a plot?', A: 'Our app facilitates its customers with a highly personalized experience by providing them with the option of choosing a plot according to their own preferences. Users can modify and sort their search on the basis of price range, size, location, road size etc.'},
            {Q: 'Can I search properties within a specific area?', A: 'Yes, through our new digital mapping system you can easily search your properties on the basis of its phase and block etc. according to your preferences. Moreover, our App also provide our users with the facility of ‘Near me’ search, through which they can search their preferred plots near their current location, which can also be navigated through Google Maps.'},
            {Q: 'Can I post images of my property on Amaraat?', A: 'No, because our App provides its users with satellite-based pictures of the required plots, which has completely eliminated the tiring act of posting pictures of the concerned property.'},
        ],
        AgentsFAQs: [
            {Q: 'Can I list the same plot that another agent has already listed?', A: 'Yes, one can but these plots will show under the single category of ‘Multiple agents’ listings. And a sorted list of different agents (according to their post timing and their ask price) dealing with that specific plot will be clearly visible to our user.'},
            {Q: 'Do I need to refresh my listings?', A: 'Yes, after 30 days each listing needs to be refreshed, or otherwise, it will be removed automatically, leaving only the fresh and authentic information available to our customers.'},
            {Q: 'Are there any hot listings? How can I prioritize my listings?', A: 'Each listing is equally valuable for us, that is why there is no such thing like ‘Hot Listings’ in our App, for which one normally pays extra money. Every Listing is ‘HOT’ at our platform.'},
            {Q: 'Can I choose my listing package?', A: 'Our packages are based on the number staff being employed by a specific agency, and our strictly prescribed by the sales agents on their visit to your office.'},
            {Q: 'How can I increase my listings quota?', A: 'We believe in providing quality data only that is why increasing any agency’s listings quota is completely onto Amaraat’s qualified staff. Our staff will independently evaluate the case for each agency, based on their size and performance and then will make this decision accordingly.'},
            {Q: 'How long is the expiry of a listing?', A: 'Catering to the problem of old and zombie ads, at the end of each month, each listing will be refreshed and if not, it will be removed automatically.'},
            {Q: 'How to edit a listing?', A: 'Just click on the edit button, make changes and save them by clicking on the submit button.'},
            {Q: 'How can I make an offer on the app?', A: 'How can I make an offer on the app? By clicking on the interested plot from the search list, one can clearly see the ‘make offer’ option in our app. In this way, you can easily offer the price of your interested plots to their authentic sellers by simply texting them in a separate chat box within the App'},
            {Q: 'How can an agency create an account on Amaraat App?', A: 'After downloading Amaraat App, the users will be initially asked to provide a verification code being sent on their Email IDs, before they can further register themselves with their unique Passwords. For an agency, all the profile details, with a randomly generated license number is required, which will be further processed and accepted through Amaraat portal before making their account active.'},
            {Q: 'How can an agent create an account on Amaraat App?', A: 'After downloading Amaraat App, the users will be initially asked to provide a verification code being sent on their phone numbers, before they can further register themselves with unique IDs and Passwords. Agents are then required to provide the uniquely allotted agency license number for their requests to be processed further. Once the registered agency will accept their requests, only then they will be made eligible to start selling plots.'},
            {Q: 'Can I modify my research for a plot?', A: 'Our app facilitates its customers with a highly personalized experience by providing them with the option of choosing a plot according to their own preferences. Users can modify and sort their search on the basis of price range, size, location, road size etc.'},
        ],
        ChoosingFAQs: [
            {Q: 'Can I search properties within a specific area?', A: 'Yes, through our new digital mapping system you can easily search your properties on the basis of its phase and block etc. according to your preferences. Moreover, our App also provide our users with the facility of ‘Near me’ search, through which they can search their preferred plots near their current location, which can also be navigated through Google Maps.'},
            {Q: 'Can I post images of my property on Amaraat?', A: 'No, because our App provides its users with satellite-based pictures of the required plots, which has completely eliminated the tiring act of posting pictures of the concerned property.'},
        ],
        UserFAQs: [
            {Q: 'How long is the expiry of a listing?', A: 'Catering to the problem of old and zombie ads, at the end of each month, each listing will be refreshed and if not, it will be removed automatically.'},
            {Q: 'How can I make an offer on the app?', A: 'How can I make an offer on the app? By clicking on the interested plot from the search list, one can clearly see the ‘make offer’ option in our app. In this way, you can easily offer the price of your interested plots to their authentic sellers by simply texting them in a separate chat box within the App'},
            {Q: 'Will I receive any information on my saved preferences?', A: 'Whenever a similar listing or a plot will be advertised, our users will be instantly notified on their App.'},
        ]
    })
}
</script>
