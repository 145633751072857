<template>
<div class="my-2">
    <v-card @click="tapped" v-if="type == 'public'">
        <v-card-text>
            <v-row class="px-3">
                <v-chip color="amber" small>{{item.index}}</v-chip>
                <v-chip class="ml-1" small>{{convertTime()}}</v-chip>
                <v-spacer/>
                <v-chip small>{{item.data.listingType}}</v-chip>
                <v-chip color="primary" small>{{item.data.propertyType}}</v-chip>
            </v-row>
            <v-row class="px-3 mt-3">
                <img :src="convertURL()" class="borderProfile">
                <div class="ml-3 mt-2">
                    <div class="headline">{{item.data.user.name}}</div>
                    <div>{{item.data.user.userType}}</div>
                </div>
                <v-spacer/>
                <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                <v-card v-else class="elevation-0 primary" dark>
                    <v-card-text class="text-center">
                        <div class="display-1 amber--text">{{count}}</div>
                        <div class="amber--text">Results</div>
                    </v-card-text>
                </v-card>
            </v-row>
            <div class="text-left mb-3">
                <div><b>Price </b>{{getPriceLimit()}}</div>
                <div><b>Size </b>{{getAreaLimit()}}</div>
            </div>
            <Address v-if="address.length > 0" :data="address" :type="'listing'" :listingType="item.data.listingType" :propertyType="item.data.propertyType" :plots="[]"/>
        </v-card-text>
    </v-card>
    <v-card @click="tapped" v-else-if="type == 'mine'">
        <v-card-text>
            <v-row class="px-3">
                <v-chip color="amber" small>{{item.index}}</v-chip>
                <v-chip class="ml-1" small>{{convertTime()}}</v-chip>
                <v-spacer/>
                <v-chip small>{{item.data.listingType}}</v-chip>
                <v-chip color="primary" small>{{item.data.propertyType}}</v-chip>
            </v-row>
            <v-row class="px-3 mt-3">
                <div class="text-left">
                    <div><b>Price </b>{{getPriceLimit()}}</div>
                    <div><b>Size </b>{{getAreaLimit()}}</div>
                    <Address v-if="address.length > 0" :data="address" :type="'listing'" :listingType="item.data.listingType" :propertyType="item.data.propertyType" :plots="[]"/>
                </div>
                <v-spacer/>
                <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                <v-card v-else class="elevation-0 primary" dark>
                    <v-card-text class="text-center">
                        <div class="display-1">{{count}}</div>
                        <div>Results</div>
                    </v-card-text>
                </v-card>
            </v-row>
        </v-card-text>
    </v-card>
</div>
</template>
<script>
import firebase from 'firebase'
import Functions from '../Functions'
import moment from 'moment'
import Data from '../Data'
export default {
    name: 'Requirement',
    props: ['type', 'item'],
    data: () => ({
        count: 0,
        loading: true,
        listings: [],
        address: []
    }),
    mounted() {
        let address = []
//        console.log(this.item.data)
        if (this.item.data.propSearchTerms != null) {
            for (let i = 0; i < this.item.data.propSearchTerms.length; i++) {
                address.push(Functions.ConvertStringToAddress(this.item.data.propSearchTerms[i]))
            }
        } else {
            for (let i = 0; i < this.item.data.searchTerms.length; i++) {
                address.push(Functions.ConvertStringToAddress(this.item.data.searchTerms[i]))
            }
        }
        this.address = address
        this.getListings()
    },
    methods: {
        convertTime() {
            if (this.item.data.timestamp.seconds != null) {
                return moment.unix(this.item.data.timestamp.seconds).utc().format("ddd Do MMM")
            } else {
                return moment.unix(this.item.data.timestamp._seconds).utc().format("ddd Do MMM")
            }
        },
        convertURL() {
            return Functions.GetImageURL(this.item.data.user.photoURL)
        },
        getListings() {
            let data = []
            firebase.firestore().collection('WishesListings').where('wishID', '==', this.item.id).orderBy('timestamp', 'desc').get()
            .then((docs) => {
                let c = 0;
                docs.forEach(doc => {
                    let m = doc.data().marker != null ? {lat: doc.data().marker.latitude, lng: doc.data().marker.longitude} : null
                    data.push({
                        id: doc.data().listID, 
                        data: doc.data(), 
                        index: data.length + 1, 
                        marker: m
                    })
                })
                this.loading = false
                this.count = data.length
                this.listings = data
            }).catch((err) => {
                console.log(err)
            })
        },
        getPriceLimit() {
            let result = 'No Limit'
            if (this.item.data.minPrice > 0 && this.item.data.maxPrice > 0) {
                result = Functions.PriceInDefaultUnit(this.item.data.minPrice) + ' - ' + Functions.PriceInDefaultUnit(this.item.data.maxPrice)
            } else {
                if (this.item.data.minPrice == 0 && this.item.data.maxPrice > 0) {
                    result = 'Max ' + Functions.PriceInDefaultUnit(this.item.data.maxPrice)
                } else if (this.item.data.minPrice > 0 && this.item.data.maxPrice == 0) {
                    result = 'Min ' + Functions.PriceInDefaultUnit(this.item.data.minPrice)
                }
            }
            return result
        },
        getAreaLimit() {
            let result = 'No Limit'
            if (this.item.data.minArea > 0 && this.item.data.maxArea > 0) {
                result = Functions.AreaInDefaultUnit(this.item.data.minArea) + ' - ' + Functions.AreaInDefaultUnit(this.item.data.maxArea)
            } else {
                if (this.item.data.minArea == 0 && this.item.data.maxArea > 0) {
                    result = 'Max  ' + Functions.AreaInDefaultUnit(this.item.data.maxArea)
                } else if (this.item.data.minArea > 0 && this.item.data.maxArea == 0) {
                    result = 'Min ' +  Functions.AreaInDefaultUnit(this.item.data.minArea)
                }
            }
            return result
        },
        tapped() {
            let user = firebase.auth().currentUser
            if (user) {
                if (this.listings.length > 0) {
                    this.$store.dispatch('ClearMarkers')
                    this.$store.dispatch('Listings', this.listings)
                    this.dialog = false
                    if (this.$router.currentRoute.fullPath != '/searchresults') {
                        this.$router.push('/searchresults')
                    }
                }
            } else {
                this.$store.dispatch('SnackBar', Data.NotSignedInSnackBar)
            }
        }
    }
}
</script>