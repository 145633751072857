<template>
<div>
    <div v-if="type == 'home'">
        <Location :type="'multiple'" :lg="'6'" :md="'6'" :sm="'12'" :xs="'12'" v-on:address="address" :dark="false"/>
        <v-row class="px-3 pt-1">
            <v-spacer/>
            <v-btn class="amber" rounded @click="openDialog" :loading="loading">Advanced Search</v-btn>
            <v-btn class="primary ml-5" rounded @click="go" :loading="loading">Go</v-btn>
        </v-row>
    </div>
    <v-btn icon @click.stop="openDialog" v-else-if="type == 'invertedicon'"><v-icon>mdi-magnify</v-icon></v-btn>
    <v-btn icon @click.stop="openDialog" v-else-if="type == 'icon'"><v-icon>mdi-magnify</v-icon></v-btn>
    <v-btn class="primary" dark rounded @click.stop="openDialog" v-else-if="type == 'text'">Search Plots</v-btn>
    <v-btn fab fixed bottom right color="amber" @click.stop="openDialog" v-else-if="type == 'fab'"><v-icon>mdi-magnify</v-icon></v-btn>
    <v-dialog v-model="dialog" :fullscreen="fullscreen" hide-overlay max-width="600" persistent scrollable transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="primary" style="max-height: 64px;">
                <v-btn icon dark @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{title}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="py-5">
                <Location :type="'multiple'" :lg="'6'" :md="'6'" :sm="'12'" v-on:address="address"/>
                <v-expansion-panels flat v-model="Advanced">
                    <v-expansion-panel class="pa-0">
                        <v-expansion-panel-header class="pa-0 primary--text font-weight-bold">Advanced</v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                            <InputPair :title="'Min Price'" :label1="'Crore'" :label2="'Lac'" :type="'price'" v-on:pair="pair" class="px-3"/>
                            <InputPair :title="'Max Price'" :label1="'Crore'" :label2="'Lac'" :type="'price'" v-on:pair="pair" class="px-3"/>
                            <v-divider/>
                            <InputPair :title="'Min Area'" :label1="'Kanal'" :label2="'Marla'" :type="'area'" v-on:pair="pair" class="px-3"/>
                            <InputPair :title="'Max Area'" :label1="'Kanal'" :label2="'Marla'" :type="'area'" v-on:pair="pair" class="px-3"/>
                            <v-divider/>
                            <InputPair :title="'Plot Range'" :label1="'From'" :label2="'To'" :type="'plot'" v-on:pair="pair" class="px-3"/>
                            <v-subheader class="font-weight-bold primary--text">Features</v-subheader>
                            <FeaturesInput :rw='false' v-on:featureUpdate="featureUpdate"/>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn class="primary" :loading="loading" @click="go" rounded>Go</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
import Data from '../Data'
import Functions from '../Functions'
import firebase from 'firebase'
import router from '../router'
import AgencyCard from './AgencyCard.vue'
import axios from 'axios'
export default {
  components: { AgencyCard },
    name: 'NewSearch',
    props: ['type', 'searchFor'],
    data: () => ({
        dialog: false,
        title: '',
        fullscreen: false,
        loading: false,
        Advanced: [],
        minPrice: 0,
        maxPrice: 0,
        minArea: 0,
        maxArea: 0,
        addresses: [],
        listingType: 'Plot',
        propertyType: 'All',
        features: {},
        plot: ''
    }),
    mounted () {
        this.loading = false
        if (this.searchFor == 'requirements') {
            this.title = 'Search Requirements'
        } else {
            this.title = 'Search Listings'
        }
        this.onResize()
        window.addEventListener('resize', this.onResize, { passive: true })
    },
    watch: {
        Advanced(newVal, oldVal) {
            this.minPrice = 0
            this.maxPrice = 0
            this.minArea = 0
            this.maxArea = 0
            this.plot = ''
            this.features = {}
            if (newVal == null) {
                this.$store.dispatch('uClear', true)
            } else {
                this.$store.dispatch('uClear', false)
            }
        }
    },
    methods: {
        onResize () {
            this.fullscreen = window.innerWidth < 600
        },
        openDialog() {
            this.dialog = true
        },
        closeDialog() {
            this.dialog = false
        },
        featureUpdate(data) {
            this.features = data
        },
        address(address, listingType, propertyType) {
            this.addresses = address
            this.listingType = listingType
            this.propertyType = propertyType
        },
        pair(type, value1, value2) {
            if (type == 'minarea') {
                this.minArea = Functions.CombineArea(value1, value2)
            } else if (type == 'maxarea') {
                this.maxArea = Functions.CombineArea(value1, value2)
            } else if (type == 'minprice') {
                this.minPrice = Functions.CombinePrice(value1, value2)
            } else if (type == 'maxprice') {
                this.maxPrice = Functions.CombinePrice(value1, value2)
            } else if (type == 'plot') {
                let v1 = 0
                let v2 = 0
                if (!isNaN(value1)) {
                    v1 = Number(value1)
                }
                if (!isNaN(value2)) {
                    v2 = Number(value2)
                }
                this.plot = `${v1}-${v2}`
            }
        },
        go() {
            if (this.addresses.length > 0 && this.searchFor == 'listings') {
                this.loading = true
                let f = Functions.ConvertFeaturesToString(this.features)
                let aSearch = firebase.functions().httpsCallable('aSearch')
                aSearch({
                    'search': this.addresses,
                    'minPrice': this.minPrice,
                    'maxPrice': this.maxPrice,
                    'minArea': this.minArea,
                    'maxArea': this.maxArea,
                    'features': f,
                    'plots': this.plot,
                    'propertyType': this.propertyType,
                    'listingType': this.listingType,
                    'searchFor': this.searchFor
                })
                .then((result) => {
                    let data = []
                    let listings = result.data.ListIDs
                    for (let i = 0; i < listings.length; i++) {
                        let m = listings[i].marker != null ? {lat: listings[i].marker.latitude, lng: listings[i].marker.longitude} : null
                        data.push({
                            id: listings[i].ListID,
                            data: listings[i], 
                            index: data.length + 1, 
                            marker: m
                        })
                    }
                    this.loading = false
                    if (data.length > 0) {
                        this.$store.dispatch('ClearMarkers')
                        this.$store.dispatch('Listings', data)
                        this.dialog = false
                        if (router.currentRoute.fullPath != '/searchresults') {
                            router.push('/searchresults')
                        }
                    }
                })
            } else if (this.addresses.length > 0 && this.searchFor == 'requirements') {
                this.loading = true
                let f = Functions.ConvertFeaturesToString(this.features)
                let aSearch = firebase.functions().httpsCallable('aSearch')
                aSearch({
                    'search': this.addresses,
                    'minPrice': this.minPrice,
                    'maxPrice': this.maxPrice,
                    'minArea': this.minArea,
                    'maxArea': this.maxArea,
                    'features': f,
                    'plots': this.plot,
                    'propertyType': this.propertyType,
                    'listingType': this.listingType,
                    'searchFor': this.searchFor
                })
                .then((result) => {
                    let data = []
                    let requirements = result.data
                    for (let i = 0; i < requirements.length; i++) {
                        data.push({
                            id: requirements[i].wishID,
                            data: requirements[i], 
                            index: data.length + 1, 
                        })
                    }
                    this.loading = false
                    if (data.length > 0) {
                        this.$store.dispatch('requirements', data)
                        this.dialog = false
                        if (router.currentRoute.fullPath != '/searchrequirementresults') {
                            router.push('/searchrequirementresults')
                        }
                    }
                })
            }
        },
    },
}
</script>