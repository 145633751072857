<template>
    <v-btn class="my-5" color="primary" :loading="loading" @click="reveal" block>{{status}}</v-btn>
</template>
<script>
import firebase from 'firebase'
export default {
    name: 'PhoneNumberReveal',
    props: ['agentID'],
    data: () => ({
        loading: false,
        status: 'Tap to reveal phone number'
    }),
    methods: {
        reveal() {
            let user = firebase.auth().currentUser
            if (user && this.status.startsWith('Tap to ')) {
                this.loading = true
                firebase.firestore().collection('PublicProfile').doc(this.agentID).get()
                .then((doc) => {
                    if (doc.exists) {
                        this.status = doc.data().phone
                        this.submitRequest()
                    }
                }).catch((err) => {
                    console.log(err)
                })
            }
        },
        submitRequest() {
            let user = firebase.auth().currentUser
            if (user) {
                firebase.firestore().collection('PendingPhoneNumberRequest').add({
                    'requestUserID': this.agentID, 'user': user.uid
                }).then(() => {
                }).catch((err) => {
                    console.log(err)
                })
                this.loading = false
            }
        }
    }
}
</script>