<template>
<div>
<v-toolbar class="primary" dark>
    <v-container>
        <v-row>
            <v-toolbar-title class="mt-3">My Agents</v-toolbar-title>
        </v-row>
    </v-container>
</v-toolbar>
<v-progress-linear v-if="loading" class="amber" indeterminate></v-progress-linear>
<v-container class="maxWidth">
    <v-row>
        <v-col md="3" v-for="a in agents" :key="a.id">
            <v-card @click="showAgent(a.id)" outlined class="elevation-0">
                <v-card-text class="text-center">
                    <img :src="getImageURL(a.data.photoURL)" class="borderProfile100">
                    <div class="font-weight-bold mt-4">{{a.data.displayName}}</div>
                    <div>{{a.data.userType}}</div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</div>
</template>
<script>
import Data from '../Data'
import Functions from '../Functions'
import firebase from 'firebase'
export default {
    name: 'MyAgents',
    data: () => ({
        loading: true,
        agents: []
    }),
    mounted () {
        this.getAgencyAgents()
    },
    methods: {
        getAgencyAgents() {
            let user = firebase.auth().currentUser
            if (user) {
                let agencyID = this.$store.state.user.agency.id
                let agents = []
                firebase.firestore().collection('PublicProfile')
                .where('userType', 'in', ['Seller', 'Agency Owner'])
                .where('agency.id', '==', agencyID)
                .get()
                .then((docs) => {
                    docs.forEach(doc => {
                        if ((doc.data().status == null || doc.data().status == 'Active') && doc.id != user.uid) {
                            agents.push({id: doc.id, data: doc.data()})
                        }
                    })
                    this.agents = agents
                    this.loading = false
                }).catch((err) => {
                    console.log(err)
                })
            }
        },
        getImageURL(url) {
            return Functions.GetImageURL(url)
        },
        showAgent(id) {
            this.$router.push(`/profile/${id}`)
        }
    }
}
</script>