import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomePage from '../views/HomePage.vue'
import Login from '../views/Login.vue'
import SearchResults from '../views/SearchResults.vue'
import AProfile from '../views/AProfile.vue'
import Requirements from '../views/Requirements.vue'
import NewListing from '../views/NewListing.vue'
import AddRequirement from '../views/AddRequirement.vue'
import FindAgencyAgent from '../views/FindAgencyAgent.vue'
import Profile from '../views/Profile'
import MyRequirements from '../views/MyRequirements'
import MyAgents from '../views/MyAgents'
import Terms from '../views/Terms'
import Testimonials from '../views/Testimonials'
import FAQs from '../views/FAQs'
import PlotOnline from '../views/PlotOnline.vue'
import SearchRequirementResults from '../views/SearchRequirementResults'
import Buy from '../views/Buy'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/buy',
    name: 'Buy',
    component: Buy
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/searchresults',
    name: 'SearchResults',
    component: SearchResults
  },
  {
    path: '/searchrequirementresults',
    name: 'SearchRequirementResults',
    component: SearchRequirementResults
  },
  {
    path: '/requirements',
    name: 'Requirements',
    component: Requirements
  },
  {
    path: '/myrequirements',
    name: 'MyRequirements',
    component: MyRequirements
  },
  {
    path: '/myagents',
    name: 'MyAgents',
    component: MyAgents
  },
  {
    path: '/addrequirement',
    name: 'AddRequirement',
    component: AddRequirement
  },
  {
    path: '/aprofile/:id',
    name: 'AProfile',
    component: AProfile
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/newlisting',
    name: 'NewListing',
    component: NewListing
  },
  {
    path: '/findagencyagent',
    name: 'FindAgencyAgent',
    component: FindAgencyAgent
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: FAQs
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: Testimonials
  },
  {
    path: '/plotonline/:listid',
    name: 'PlotOnline',
    component: PlotOnline
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
