<template>
    <div>
        <v-chip v-if="data.Corner" class="mx-1" small><v-icon small>mdi-rounded-corner</v-icon></v-chip>
        <v-chip v-if="data.Posession" class="mx-1" small><v-icon small>mdi-hand-heart</v-icon></v-chip>
        <v-chip v-if="data.Pole" class="mx-1" small><v-icon small>mdi-outdoor-lamp</v-icon></v-chip>
        <v-chip v-if="data.DP" class="mx-1" small><v-icon small>mdi-flash</v-icon></v-chip>
        <v-chip v-if="data.FacingPark" class="mx-1" small><v-icon small>mdi-pine-tree</v-icon></v-chip>
        <v-chip class="mx-1" small><v-icon left small>mdi-road-variant</v-icon>{{data.RoadWidth}}</v-chip>
    </div>
</template>
<script>
export default {
    name: 'Features',
    props: ['data', 'status'],
    data: () => ({

    })
}
</script>
