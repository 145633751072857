<template>
<div>
<v-toolbar class="primary" dark>
    <v-container>
        <v-row>
            <v-toolbar-title class="mt-3">Add Requirement</v-toolbar-title>
        </v-row>
    </v-container>
</v-toolbar>
<v-progress-linear v-if="loading" class="amber" indeterminate></v-progress-linear>
<v-container class="maxWidth">
    <v-card>
        <v-toolbar dense class="elevation-0 grey lighten-3">
            <v-toolbar-title>Sharing</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-select v-model="wishType" :items="wishTypes" label="Wish Type" outlined dense hide-details />    
        </v-card-text>
        <v-toolbar dense class="elevation-0 grey lighten-3">
            <v-toolbar-title>Location</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <Location :type="'multiple'" :lg="'6'" :md="'6'" :sm="'12'" v-on:address="address"/>
        </v-card-text>
        <v-toolbar dense class="elevation-0 grey lighten-3">
            <v-toolbar-title>Price</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <InputPair :title="'Min Price'" :label1="'Crore'" :label2="'Lac'" :type="'minprice'" v-on:pair="pair" class="px-3"/>
            <InputPair :title="'Max Price'" :label1="'Crore'" :label2="'Lac'" :type="'maxprice'" v-on:pair="pair" class="px-3"/>
        </v-card-text>
        <v-toolbar dense class="elevation-0 grey lighten-3">
            <v-toolbar-title>Area</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <InputPair :title="'Min Area'" :label1="'Kanal'" :label2="'Marla'" :type="'minarea'" v-on:pair="pair" class="px-3"/>
            <InputPair :title="'Max Area'" :label1="'Kanal'" :label2="'Marla'" :type="'maxarea'" v-on:pair="pair" class="px-3"/>
        </v-card-text>
        <v-toolbar dense class="elevation-0 grey lighten-3">
            <v-toolbar-title>Plot Range</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <InputPair :title="'Plot Range'" :label1="'From'" :label2="'To'" :type="'plot'" v-on:pair="pair" class="px-3"/>
        </v-card-text>
        <v-toolbar dense class="elevation-0 grey lighten-3">
            <v-toolbar-title>Features</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <FeaturesInput :rw='false' v-on:featureUpdate="featureUpdate"/>
        </v-card-text>
        <v-row class="pa-6">
            <v-spacer/>
            <v-btn class="primary" dark large :loading="loading" @click="save">Save</v-btn>
        </v-row>
    </v-card>
</v-container>
</div>
</template>
<script>
import Data from '../Data'
import Functions from '../Functions'
import firebase from 'firebase'
export default {
    name: 'AddRequirement',
    data: () => ({
        loading: false,
        selectedAddress: [],
        wishType: 'Public',
        listingType: 'Plot',
        propertyType: 'Residential',
        wishTypes: Data.WishTypes,
        minPrice: 0,
        maxPrice: 0,
        minArea: 0,
        maxArea: 0,
        features: {},
        plot: ''
    }),
    mounted () {
    },
    methods: {
        address(address, listingType, propertyType) {
            this.selectedAddress = address
            this.listingType = listingType
            this.propertyType = propertyType
        },
        pair(type, value1, value2) {
            if (type == 'minarea') {
                this.minArea = Functions.CombineArea(value1, value2)
            } else if (type == 'maxarea') {
                this.maxArea = Functions.CombineArea(value1, value2)
            } else if (type == 'minprice') {
                this.minPrice = Functions.CombinePrice(value1, value2)
            } else if (type == 'maxprice') {
                this.maxPrice = Functions.CombinePrice(value1, value2)
            } else if (type == 'plot') {
                let v1 = 0
                let v2 = 0
                if (!isNaN(value1)) {
                    v1 = Number(value1)
                }
                if (!isNaN(value2)) {
                    v2 = Number(value2)
                }
                this.plot = `${v1}-${v2}`
            }
        },
        featureUpdate(data) {
            this.features = data
        },
        save() {
            if (this.selectedAddress.length > 10) {
                alert("Not more than 10 Blocks")
            } else if (Functions.CheckAddress(this.selectedAddress[0]) == false) {
                alert("Invalid Address")
            } else {
                let user = firebase.auth().currentUser
                if (user && !this.loading) {
                    let w = {}
                    if (this.listingType == 'Plot') {
                        w = {
                            'user': user.uid,
                            'features': this.features,
                            'listingType': this.listingType,
                            'propertyType': this.propertyType,
                            'maxArea': this.maxArea,
                            'minArea': this.minArea,
                            'maxPrice': this.maxPrice,
                            'minPrice': this.minPrice,
                            'searchTerms': this.selectedAddress,
                            'sharing': this.wishType,
                            'plots': this.plot
                        }
                    } else if (this.listingType == 'File') {
                        w = {
                            'user': user.uid,
                            'features': '00000',
                            'listingType': this.listingType,
                            'propertyType': this.propertyType,
                            'maxArea': this.maxArea,
                            'minArea': this.minArea,
                            'maxPrice': this.maxPrice,
                            'minPrice': this.minPrice,
                            'searchTerms': this.selectedAddress,
                            'sharing': this.wishType,
                            'plots': '0-0'
                        }
                    }
                    this.loading = true
                    firebase.firestore().collection('PendingWishes').add(w)
                    .then((ref) => {
                        this.loading = false
                        this.$store.dispatch('SnackBar', {
                            status: true,
                            msgText: 'Requirement has been saved, it will be few moments before it will be posed.',
                            color: 'green darken-4',
                            timeout: -1,
                            closeAble: true
                        })
                        this.$router.back()
                    }).catch((err) => {
                        this.loading = false
                        this.$store.dispatch('SnackBar', {status: true, msgText: err, color: 'red darken-4', timeout: -1, closeAble: true})
                    })
                }

            }

        }
    }
}
</script>