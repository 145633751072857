<template>
<div>
    <div>
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6" v-if="!$vuetify.breakpoint.mobile" align="center" justify="center">
                    <v-img src="./img/image.png" class="appImage elevation-2"/>
                </v-col>
                <v-col cols="12" md="6" align="center" justify="center">
                    <div class="primary--text largerFont font-weight-bold">Amaraat ...</div>
                    <vue-typer
                        :text='[
                            "Buy/Sell Plot or File",
                            "Not more than 30 day old listings",
                            "Get notified when your required plot becomes available",
                            "Contact Buyer/Seller directly",
                            "Send/Accept offers online",
                            "Get directions to any plot",
                            "Anywhere access"
                        ]'
                        :repeat='Infinity'
                        :shuffle='false'
                        initial-action='typing'
                        :pre-type-delay='70'
                        :type-delay='70'
                        :pre-erase-delay='2000'
                        :erase-delay='250'
                        erase-style='select-all'
                        :erase-on-complete='false'
                        caret-animation='expand'
                    ></vue-typer>
                    <div class="py-4"/>
                    <h4 class="primary--text">Download now</h4>
                    <v-row class="ml-1 mt-2"  align="center" justify="center">
                        <v-btn class="primary elevation-5" rounded><v-icon color="white">mdi-apple</v-icon></v-btn>
                        <v-btn class="primary elevation-5 mx-2" rounded><v-icon color="white">mdi-google</v-icon></v-btn>
                        <v-btn class="primary elevation-5" rounded><v-icon color="white">mdi-microsoft-windows</v-icon></v-btn>
                    </v-row>
                    <div class="homeSearchBox grey lighten-4 elevation-1 px-12 py-6 mt-6" v-if="!$vuetify.breakpoint.mobile">
                        <h2 class="primary--text">Search Plot or File</h2>
                        <div class="py-2"/>
                        <NewSearch type="home" searchFor="listings"/>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
    <div class="grey lighten-3">
        <div class="py-3"/>
        <v-container>
            <h2 class="">New Listings on Amaraat</h2>
            <div v-if="!loading" class="primary--text linkCursor" @click="MenuClick('/buy')">View all latest listings <v-icon color="primary" class="mb-1">mdi-arrow-right-drop-circle</v-icon></div>
            <v-progress-linear v-if="loading" class="amber" indeterminate></v-progress-linear>
            <v-row>
                <v-col md="4" v-for="L in listings" :key="L.id">
                    <NewListingCard :item="L" :index="L.index"/>
                </v-col>
            </v-row>
            <v-row class="mt-3">
                <v-spacer/>
                <v-btn class="primary" rounded @click="MenuClick('/buy')" :loading="loading">View all latest listings</v-btn>
            </v-row>
        </v-container>
        <div class="py-3"/>
    </div>
    <v-container>
        <div class="py-6"/>
        <h1>Why Amaraat</h1>
        <h4 class="subheading primary--text">Our Mission Is To Enable A Transparent And Free Solution For All Pakistanis & Expats Around The World To Buy And Sell Plots With Ease.</h4>
        <v-row class="text-center">
            <v-col md="4" class="py-12" v-for="(item, index) in features" :key="index">
                <v-img :src="item.image" height="100" contain/>
                <div class="py-2"/>
                <div class="font-weight-black primary--text">{{item.title}}</div>
                <div>{{item.desc}}</div>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import firebase from 'firebase'
import moment from 'moment'
import { VueTyper } from 'vue-typer'
export default {
    name: 'Home',
    components: {VueTyper},
    data: () => ({
        features: [
            {title: 'Up to date', desc: 'Not more than 30 day old listings', image: 'img/undraw_Up_to_date_re_nqid.svg'},
            {title: 'Requirements', desc: 'Get notified when you the plot you want becomes available', image: 'img/undraw_Wishlist_re_m7tv.svg'},
            {title: 'Driving Directions', desc: 'Navigate to your plot', image: 'img/undraw_Directions_re_kjxs.svg'},
            {title: 'Online Offer', desc: 'Never miss to make your offer', image: 'img/undraw_wallet_aym5.svg'},
            {title: 'Phase Maps', desc: 'Up to date phase maps available to view/download', image: 'img/undraw_map_1r69.svg'},
            {title: 'Anywhere access', desc: 'Available on Web/App and Play store.', image: 'img/undraw_web_devices_ad58.svg'},
        ],
        date: null,//new Date().toISOString().substr(0, 10),
        menu: false,
        loading: true,
        listType: 'active',
        markers: [],
        listings: [],
        mapCenter: {lat:31.595228, lng:74.302232},
        selectedDate: moment().format('dddd, Do MMM, YYYY'),
    }),
    mounted() {
        this.getFirst()
    },
    watch: {
        date(newVal, oldVal) {
            this.listings = []
            this.agencies = []
            let date1 = new Date(newVal)
            date1.setDate(date1.getDate() + 1)
            this.getTheRest(date1)
        }
    },
    methods: {
        MenuClick(path) {
            this.$router.push(path)
        },
        getFirst() {
            this.loading = true
            let dated = 0
            firebase.firestore().collection('Search')
            .orderBy('timestamp', 'desc')
            .limit(1)
            .where('status', '==', 'Active')
            .get()
            .then((docs) => {
                docs.forEach(doc => {
                    dated = doc.data().timestamp.seconds*1000
                })
                this.getTheRest(dated)
            }).catch((err) => {
                console.log(err)
            })
        },
        getTheRest(date) {
            this.loading = true
            let marker = null
            let data = []
            let agenciesData = []
            let date1 = new Date(date)
            this.selectedDate = moment(date1).format('dddd, Do MMM, YYYY')
            let today = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate())
            let timestamp = firebase.firestore.Timestamp.fromDate(today)

            let nextDay = moment(date1)
            nextDay = nextDay.add(1, 'days')
            let tomorrow = new Date(nextDay.year(), nextDay.month(), nextDay.date())
            let ntimestamp = firebase.firestore.Timestamp.fromDate(tomorrow)
            firebase.firestore().collection('Search')
            .orderBy('timestamp', 'desc')
            .where('status', '==', 'Active')
            .where('timestamp', '>=', timestamp)
            .where('timestamp', '<', ntimestamp)
            .limit(6)
            .get()
            .then((docs) => {
                docs.forEach(doc => {
                    let m = doc.data().marker != null ? {lat: doc.data().marker.latitude, lng: doc.data().marker.longitude} : null
                    if (marker == null) {
                        marker = m
                    }
                    data.push({
                        id: doc.id, 
                        data: doc.data(), 
                        index: data.length + 1, 
                        marker: m
                    })
                    let s = doc.data().search
                    for (let k in s) {
                        if (s[k].startsWith('Agency')) {
                            let a = s[k].replace('Agency:', '')
                            let index = agenciesData.findIndex(ag => ag.id == a)
                            if (index < 0) {
                                agenciesData.push({id: a})
                            }
                            break
                        }
                    }
                })
                this.$store.dispatch('ClearMarkers')
                this.listings = data
                this.mapCenter = marker
                this.agencies = agenciesData
                this.loading = false
            }).catch((err) => {
                console.log(err)
            })
        },
    }
}
</script>