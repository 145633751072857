<template>
<v-container>
    <v-skeleton-loader ref="skeleton" type="card" class="mx-auto" v-if="loading"></v-skeleton-loader>
    <div v-else>
        <v-card>
        <GmapMap :center="mapCenter" :zoom="15" map-type-id="satellite" class="map" style="height: 300px;">
            <GmapMarker
                :key="index"
                v-for="(m, index) in Markers"
                :position="m.position"
                :clickable="true"
                :draggable="false"
                @click="center=m.position"/>
        </GmapMap>
        </v-card>
        <div class="text-left mt-5">
            <h2>{{Category}}</h2>
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                        <tr v-for="(item,i) in Table1" :key="i">
                        <td>{{ item.key }}</td>
                        <td>{{ item.value }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div class="text-left mt-5" v-if="Category == 'Plot'">
            <h2>Features</h2>
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                        <tr v-for="(item,i) in Table2" :key="i">
                        <td>{{ item.key }}</td>
                        <td v-if="item.value"><v-icon color="green">mdi-check-all</v-icon></td>
                        <td v-else><v-icon color="red">mdi-close</v-icon></td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div class="text-left mt-5">
            <h2>Seller</h2>
            <v-card v-if="Seller != null" color="grey lighten-3">
                <v-card-text class="text-center">
                    <h1 class="my-5">{{Seller.Price}}</h1>
                    <img :src="Seller.AgencyProfile" height="150" contain class="borderProfile100"/>
                    <img :src="Seller.AgentProfile" height="80" contain class="borderProfile"/>
                    <h2 class="my-5">{{Seller.AgencyName}}</h2>
                    <h4>{{Seller.AgentName}}</h4>
                    <i>Listed <b>{{Seller.Since}}</b></i>
                </v-card-text>
            </v-card>
        </div>
    </div>
    <h2 class="mt-5">Why download Amaraat ?</h2>
    <WhyAmaraat/>
</v-container>
</template>

<script>
import firebase from 'firebase'
import moment from 'moment'
import Functions from '../Functions'
export default {
    name: 'PlotOnline',
    data: () => ({
        loading: true,
        Zoom: 18,
        mapCenter: { lat: 31.46586, lng: 74.43073 },
        Markers: [{position: { lat: 31.46586, lng: 74.43073 }}],
        ListID: '',
        Category: 'Plot',
        Table1: [],
        Table2: [],
        Seller: null
    }),
    created() {
    },
    mounted() {
        this.mapCenter = {lat: 31.46424, lng: 74.42854}
        this.Markers[0] = {position: {lat: 31.46424, lng: 74.42854}}
        this.ListID = this.$router.currentRoute.params.listid
        this.GetListingDetails()
    },
    methods: {
        GetListingDetails() {
            firebase.firestore().collection('ActiveListings').doc(this.ListID).get()
            .then((doc) => {
                if (doc.exists) {
                    this.Category = doc.data().listingType
                    //this.Table1.push({key : "Type", value: result.Type})
                    this.Table1.push({key : "Space", value: this.ToMarla(Number(doc.data().size)) + " Marla"})
                    this.Table1.push({key : "Phase", value: doc.data().address.phase})
                    this.Table1.push({key : "Area", value: doc.data().address.society})
                    if (doc.data().listingType == "Plot") {
                        //this.Table1.push({key : "Plot", value: result.Plot.Plot})
                        this.Table1.push({key : "Block", value: doc.data().address.block})
                        this.Table1.push({key : "Road Width", value: doc.data().features.RoadWidth + " ft"})
                        this.Table2.push({key: 'Posession', value: doc.data().features.Posession})
                        this.Table2.push({key: 'Corner', value: doc.data().features.Corner})
                        this.Table2.push({key: 'DP', value: doc.data().features.DP})
                        this.Table2.push({key: 'Facing Park', value: doc.data().features.FacingPark})
                        this.Table2.push({key: 'Pole', value: doc.data().features.Pole})
                    }
                    else if (doc.data().listingType == "File") {
                        //this.Table1.push({key : "Allocation", value: result.Plot.Plot})
                        this.Table1.push({key : "Mouza", value: doc.data().address.block})
                    }
                    this.Seller = {}
                    this.Seller.Price = this.ToCrore(Number(doc.data().price)) + " Cr"
                    this.Seller.AgencyProfile = Functions.GetImageURL(doc.data().agency.photoURL)
                    this.Seller.AgentProfile = Functions.GetImageURL(doc.data().agent.photoURL)
                    this.Seller.AgencyName = doc.data().agency.name
                    this.Seller.AgentName = doc.data().agent.name
                    this.Seller.Since = moment(doc.data().timestamp.milliseconds).format("DD MMM, YYYY")
                }
                this.loading = false
            }).catch((err) => {
                this.loading = false
                console.log(err)
            })
        },
        ToMarla(size) {
            let Area = 0
            if (!isNaN(size)) {
                Area = Number(size)/272.25
            }
            return Area.toFixed(2)
        },
        ToCrore(price) {
            let Price = 0
            if (!isNaN(price)) {
                Price = Number(price)/10000000
            }
            return Price.toFixed(2)
        }
    }
}
</script>
<style scoped>
.mapCard {
    height: 300px;
}
</style>