import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase'
import Functions from '../Functions'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      userType: "Buyer",
      displayName: "Buyer",
      email: "",
      uid: "",
      gender: "Not Specified",
      phoneNumber: "",
      photoURL: "https://imlaak.blob.core.windows.net/image/baseline_account_circle_black_48dp.png",
      agency: null
    },
    markers: [],
    listings: [],
    snackBar: {status: false, msgText: '', color: 'black', timeout: -1},
    uClear: false,
    requirements: []
  },
  mutations: {
    AddMarker: (state, option) => {
      state.markers.push(option)
    },
    ClearMarkers: (state, option) => {
      state.markers = []
    },
    User: (state, option) => {
      state.user = option
    },
    Listings: (state, option) => {
      state.listings = option
    },
    SnackBar: (state, option) => {
      state.snackBar = option
    },
    uClear: (state, option) => {
      state.uClear = option
    },
    requirements: (state, option) => {
      state.requirements = option
    },
  },
  actions: {
    User: (context, option) => {
      let user = option
      if (user != null) {
        firebase.firestore().collection('PublicProfile').doc(user.uid).get()
        .then((doc) => {
            if (doc.exists) {
              context.commit('User', {
                userType: doc.data().userType,
                displayName: doc.data().displayName,
                email: doc.data().email,
                uid: user.uid,
                gender: doc.data().gender,
                phoneNumber: doc.data().phone,
                photoURL: Functions.GetImageURL(doc.data().photoURL),
                agency: doc.data().agency
              })
            }
            else {
              context.commit('User', Functions.user)
            }
        }).catch((err) => {
          context.commit('User', Functions.user)
        })
      }
      else {
        context.commit('User', Functions.user)
      }
    },
    AddMarker: (context, option) => {
      context.commit('AddMarker', option)
    },
    Listings: (context, option) => {
      let listings = option
      context.commit('Listings', listings)
    },
    ClearListings: (context, option) => {
      context.commit('Listings', [])
    },
    ClearMarkers: (context, option) => {
      context.commit('ClearMarkers', [])
    },
    SnackBar: (context, option) => {
      context.commit('SnackBar', option)
    },
    uClear: (context, option) => {
      context.commit('uClear', option)
    },
    requirements: (context, option) => {
      context.commit('requirements', option)
    },
  },
  modules: {
  }
})
