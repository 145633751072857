<template>
<div>
    <v-row v-if="type == 'dialog'">
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{getCityName()}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>City</v-list-item-action>
        </v-list-item>
    </v-row>
    <div>
        <span><b>{{listingType}} {{propertyType}} {{getPlots()}}</b></span>
    </div>
    <div>
        <div>{{getSocietyName()}}, {{getPhaseName()}} Phase, {{getBlockName()}} Block, <span><b>{{getCityName()}}</b></span></div>
    </div>
    <!--
    <v-row class="pa-1" v-if="type == 'listing'">
        <v-col cols="3" class="pa-0">
            <div class="text-center addressDiv">
                <div>{{data.city}}</div>
                <div class="addressType">City</div>
            </div>
        </v-col>
        <v-col cols="3" class="pa-0">
            <div class="text-center addressDiv">
                <div>{{data.society}}</div>
                <div class="addressType">Society</div>
            </div>
        </v-col>
        <v-col cols="3" class="pa-0">
            <div class="text-center addressDiv">
                <div>{{data.phase}}</div>
                <div class="addressType">Phase</div>
            </div>
        </v-col>
        <v-col cols="3" class="pa-0">
            <div class="text-center">
                <div>{{data.block}}</div>
                <div class="addressType">Block</div>
            </div>
        </v-col>
    </v-row>
    -->
</div>
</template>
<script>
import Functions from '../Functions'
export default {
    name: 'Address',
    props: ['data', 'type', 'listingType', 'propertyType', 'plots'],
    data: () => ({

    }),
    mounted() {
    },
    methods: {
        getPlots() {
            let result = ''
            for (let i = 0; i < this.plots.length; i++) {
                if (result != '') {
                    result += ', '
                }
                result += this.plots[i].plotlabel
            }
            return result
        },
        getCityName() {
            return Functions.GetCityName(this.data[this.data.length-1].city)
        },
        getSocietyName() {
            return Functions.GetSocietyName(this.data[this.data.length-1].city, this.data[this.data.length-1].society)
        },
        getPhaseName() {
            return Functions.GetPhaseName(this.data[this.data.length-1].city, this.data[this.data.length-1].society, this.data[this.data.length-1].phase)
        },
        getBlockName() {
            let blocks = []
            for (let i = 0; i < this.data.length; i++) {
                blocks.push(this.data[i].block)
            }
            return Functions.GetBlockName(this.data[this.data.length-1].city, this.data[this.data.length-1].society, this.data[this.data.length-1].phase, blocks, this.propertyType)
        }
    }
}
</script>
<style>
.addressDiv {
    border-right: 1px solid lightgray;
}
.addressType {
    font-size: 10px;
}
</style>