<template>
<v-row class="pa-5">
    <v-checkbox v-model="corner" label="Corner" class="mx-2 mt-0"></v-checkbox>
    <v-checkbox v-model="dp" label="DP" class="mx-2 mt-0"></v-checkbox>
    <v-checkbox v-model="facingPark" label="Facing Park" class="mx-2 mt-0"></v-checkbox>
    <v-checkbox v-model="pole" label="Pole" class="mx-2 mt-0"></v-checkbox>
    <v-checkbox v-model="posession" label="Posession" class="mx-2 mt-0"></v-checkbox>
    <!--
    <v-col cols="6">
        <v-checkbox v-model="armyUpdate" label="Army Update" class="mx-2 mt-0"></v-checkbox>
        <v-checkbox v-model="allPaid" label="All Paid" class="mx-2 mt-0"></v-checkbox>
        <v-checkbox v-model="facingParking" label="Facing Parking" class="mx-2 mt-0"></v-checkbox>
        <v-checkbox v-model="nearMosque" label="Near Mosque" class="mx-2 mt-0"></v-checkbox>
        <v-checkbox v-model="nearCommercial" label="Near Commercial" class="mx-2 mt-0"></v-checkbox>
    </v-col>
    -->
    <v-col cols="9" v-if="rw">
        <v-text-field label="Road Width (in feet)" v-model="roadWidth" outlined dense hide-details rounded/>
    </v-col>
</v-row>
</template>
<script>
export default {
    name: 'FeaturesInput',
    props: ['rw'],
    data: () => ({
        corner: false,
        posession: false,
        facingPark: false,
        dp: false,
        pole: false,

        armyUpdate: false,
        allPaid: false,
        facingParking: false,
        nearMosque: false,
        nearCommercial: false,

        roadWidth: ''
    }),
    watch: {
        corner(newVal, oldVal) {
            if (newVal != oldVal) {
                this.updateFeatures()
            }
        },
        posession(newVal, oldVal) {
            if (newVal != oldVal) {
                this.updateFeatures()
            }
        },
        facingPark(newVal, oldVal) {
            if (newVal != oldVal) {
                this.updateFeatures()
            }
        },
        dp(newVal, oldVal) {
            if (newVal != oldVal) {
                this.updateFeatures()
            }
        },
        pole(newVal, oldVal) {
            if (newVal != oldVal) {
                this.updateFeatures()
            }
        },
        roadWidth(newVal, oldVal) {
            this.updateFeatures()
        },
        uClear(newVal, oldVal) {
            if (newVal) {
                this.corner = false
                this.posession = false
                this.facingPark = false
                this.dp = false
                this.pole = false
            }
        }
    },
    methods: {
        updateFeatures() {
            this.$emit("featureUpdate", {
                corner: this.corner,
                posession: this.posession,
                facingPark: this.facingPark,
                dp: this.dp,
                pole: this.pole,
                roadWidth: this.roadWidth
            })
        }
    },
    computed: {
        uClear() {
            return this.$store.state.uClear
        }
    }
}
</script>