<template>
<div>
    <v-toolbar class="primary" dark>
        <v-container>
            <v-row>
                <v-toolbar-title class="mt-3">Public Requirements</v-toolbar-title>
                <v-spacer/>
                <NewSearch type="invertedicon" searchFor="requirements"/>
                <v-btn icon dark @click="menuClicked('/addrequirement')" v-if="user.uid != ''"><v-icon>mdi-plus</v-icon></v-btn>
            </v-row>
        </v-container>
    </v-toolbar>
    <v-progress-linear v-if="loading" class="amber" indeterminate></v-progress-linear>
    <v-container class="maxWidth">
        <!--
        <Location :type="'single'" v-on:address="address"/>
        <Location :type="'multiple'" v-on:address="address"/>
        -->
        <Requirement v-for="r in requirements" :key="r.id" type='public' :item='r'/>
    </v-container>
    <!--
    <NewSearch type="fab" searchFor="requirements"/>
    -->
</div>
</template>
<script>
import firebase from 'firebase'
export default {
    name: 'Requirements',
    data: () => ({
        requirements: [],
        loading: false,
    }),
    mounted() {
        this.getRequirements()
    },
    methods: {
        address(address) {
            console.log(address)
        },
        getRequirements() {
            this.loading = true
            let data = []
            firebase.firestore().collection('PublicWishes').orderBy('timestamp', 'desc').limit(25).get()
            .then((docs) => {
                docs.forEach(doc => {
                    data.push({id: doc.id, data: doc.data(), index: data.length + 1})
                })
                this.requirements = data
                this.loading = false
            }).catch((err) => {
                console.log(err)
            })
        },
        menuClicked(path) {
            if (path == 'mine') {

            } else {
                this.$router.push(path)
            }
        },
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    }
}
</script>