<template>
  <v-app>
    <app-header/>
    <v-main>
      <router-view/>
    </v-main>
    <v-snackbar v-model="snackBar.status" :timeout="snackBar.timeout" top centered :color="snackBar.color" vertical>
      {{ snackBar.msgText }}
      <template v-slot:action="{ attrs }" v-if="snackBar.closeAble">
        <v-btn dark v-bind="attrs" @click="closeSnackBar">Close</v-btn>
      </template>
    </v-snackbar>
    <v-footer dark class="pa-5">
      <v-container grid-list-xl class="pa-5">
        <v-layout row wrap class="pa-5">
          <v-flex xs12 sm12 md6 class="pa-5">
            <v-text-field dense label="Name" placeholder="Your Name" outlined rounded></v-text-field>
            <v-text-field dense label="Email" placeholder="Your Email" outlined rounded></v-text-field>
            <v-text-field dense label="Phone" placeholder="Your Phone" outlined rounded></v-text-field>
            <v-textarea dense label="Message" placeholder="Your Message" outlined rounded></v-textarea>
            <v-btn class="primary" rounded>Send</v-btn>
          </v-flex>
          <v-flex xs12 sm12 md6 class="pa-5">
            <h4>Download Amaraat</h4>
            <div>
              <v-btn icon x-large><v-icon color="blue">mdi-apple-ios</v-icon></v-btn>
              <v-btn icon x-large><v-icon color="green">mdi-google-play</v-icon></v-btn>
            </div>
            <h4>Connect With US</h4>
            <div>
              <v-btn icon><v-icon color="blue">mdi-facebook</v-icon></v-btn>
              <v-btn icon><v-icon color="blue lighten-3">mdi-twitter</v-icon></v-btn>
              <v-btn icon><v-icon>mdi-instagram</v-icon></v-btn>
              <v-btn icon><v-icon color="red">mdi-youtube</v-icon></v-btn>
            </div>
            <div class="my-5"></div>
            <h4>A Project of AT& Media Pvt Ltd</h4>
            <v-btn block outlined rounded class="my-3 contactbtn" disabled>News</v-btn>
            <v-btn block outlined rounded class="my-3 contactbtn" @click="MenuClick('/terms')">Terms & Conditions</v-btn>
            <v-btn block outlined rounded class="my-3 contactbtn" @click="MenuClick('/faqs')">FAQs</v-btn>
            <v-btn block outlined rounded class="my-3 contactbtn" @click="MenuClick('/testimonials')">Testimonials</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-footer>
    <v-bottom-sheet v-model="updateAlert" persistent>
      <v-sheet class="text-center" height="200px">
        <div class="py-3 pt-12">
          Update is ready. Please update. It will take less than 30 seconds.
        </div>
        <v-btn color="primary" dark rounded @click="getUpdate">Update</v-btn>
      </v-sheet>
    </v-bottom-sheet>
  </v-app>
</template>
<script>
import Data from './Data'
export default {
  data: () => ({
    registration:null,
    isRefresh: false,
    refreshing: false,
    updateAlert: false
  }),
  created() {
    document.addEventListener(
      'serviceWorkerUpdateEvent', this.appUpdateUI, { once: true }
    );
    navigator.serviceWorker.addEventListener(
      'controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      }
    );
  },
  methods: {
    MenuClick(path) {
      this.$router.push(path)
    },
    appUpdateUI(e) {
      this.registration = e.detail
      this.isRefresh = true
      this.updateAlert = true
    },
    getUpdate() {
      this.updateAlert = false
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({type:'SKIP_WAITING'});
      }
    },
    closeSnackBar() {
      this.$store.dispatch('SnackBar', Data.DefaultSnackBar)
    }
  },
  computed: {
    snackBar() {
      return this.$store.state.snackBar
    }
  }
}
</script>

<style>
:root {
  --primary: #4863A0;
}

#app {
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.v-card {
  border-radius: 20px !important;
}
.appImage {
  border-radius: 30px;
  width: 300px;
  height: auto;
  border: 10px solid black;
}
.listing {
  position: relative;
  border: 1px solid lightgray;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  overflow-x: hidden;
}
.listDiv {
  height: 92vh;
  overflow-y: scroll;
}

.logo {
  cursor: pointer;
}
.logoTitle {
  display: inline !important;
  font-size: 2em;
  font-weight: bold;
  color: var(--primary);
}
.mapDiv {
  position: sticky;
  top: 60px;
  height: 95vh;
  width: 100%;
  border-radius: 20px;
}
.mapDivSearch {
  position: sticky;
  top: 60px;
  height: 90vh;
  width: 100%;
  border-radius: 20px;
}
.map {
  width: 100%;
  height: 100%;
}
.avatar {
  cursor: pointer;
}
.homeContainer {
  width: 100% !important;
  height: 100% !important;
}
.container {
  max-width: 1185px !important;
}
.homeSearch {
  position: -webkit-sticky; /* Safari */
  position: sticky !important;
  top: 90px;
}
.linkCursor:hover {
  cursor: pointer;
}
.sticky {
  position: sticky !important;
  top: 150px;
}
.pageTitle {
  color: var(--primary);
  font-size: 4em;
}
.newFont {
  font-family: 'Montserrat', sans-serif !important;
}
.bolder {
  font-weight: bolder !important;
}
.maxWidth {
  max-width: 1000px !important;
}
.vue-map {
  border-radius: 10px;
  box-shadow: 0px 0px 3px 3px #999999;
}
.borderProfile {
  height: 60px;
  width: 60px;
  border: 1px solid rgb(200,200,200);
  border-radius: 10px;
  background: rgba(200, 200, 200, 0.5);
}
.borderProfile100 {
  height: 100px;
  width: 100px;
  border: 1px solid rgb(200,200,200);
  border-radius: 10px;
  background: rgba(200, 200, 200, 0.5);
}
.largerFont {
  font-size: 2em;
}
.namesDiv {
    display: inline;
    position: relative;
    left: -20px;
}
.homeSearchBox {
  border: 1px solid var(--primary);
  border-radius: 20px;
}
.vue-typer {
  width: 100%;
  font-size: 1.5em;
}
@media only screen and (max-width: 600px) {
  .logoTitle {
    display: inline !important;
    font-size: 1em;
    font-weight: bold;
    color: var(--primary);
  }
  .largerFont {
    font-size: 1em;
  }
  .borderProfile {
    height: 30px;
    width: 30px;
  }
  .borderProfile100 {
    height: 50px;
    width: 50px;
  }
  .namesDiv {
    left: 0px;
  }
  .vue-typer {
    width: 100%;
    font-size: 1.2em;
  }
}

</style>
