<template>
<div>
<v-toolbar class="primary" dark>
    <v-container>
        <v-row>
            <v-toolbar-title class="mt-3">My Profile</v-toolbar-title>
        </v-row>
    </v-container>
</v-toolbar>
<v-progress-linear v-if="loading" class="amber" indeterminate></v-progress-linear>
<v-container class="maxWidth">
    <v-card>
        <v-card-text>
            <v-row>
                <v-col md="3" class="text-center">
                    <img :src="photoURL" class="borderProfile100">
                </v-col>
                <v-col md="9">
                    <div class="display-1 font-weight-bold">{{displayName}}</div>
                    <div class="">{{userType}}</div>
                </v-col>
            </v-row>
        </v-card-text>
            <v-toolbar dense class="elevation-0 grey lighten-3">
                <v-toolbar-title>Listings</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-row class="text-center" v-if="id != ''">
                    <v-col md="4"><Stat :id="id" :type="'Agent'" :status="'Active'" :show="'card'"/></v-col>
                    <v-col md="4"><Stat :id="id" :type="'Agent'" :status="'Sold'" :show="'card'"/></v-col>
                    <v-col md="4"><Stat :id="id" :type="'Agent'" :status="'Off'" :show="'card'"/></v-col>
                </v-row>
            </v-card-text>
    </v-card>
</v-container>
</div>
</template>
<script>
import firebase from 'firebase'
import Functions from '../Functions'
export default {
    name: 'Profile',
    data: () => ({
        loading: false,
        id: '',
        displayName: '',
        userType: '',
        photoURL: '',
    }),
    mounted() {
        this.id = this.$route.params.id
        this.getProfile()
    },
    methods: {
        getProfile() {
            this.loading = true
            firebase.firestore().collection('PublicProfile').doc(this.id).get()
            .then((doc) => {
                this.displayName = doc.data().displayName
                this.userType = doc.data().userType
                this.photoURL = Functions.GetImageURL(doc.data().photoURL)
                this.loading = false
            }).catch((err) => {
                console.log(err)
            })
        }
    }
}
</script>