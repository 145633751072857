export default {
    Primary: '4863A0',
    ListingTypes: ["Plot", "File"],
    Types: ["All", "Residential", "Commercial"],
    PossessionTypes: ["Allocation", "Affidavit"],
    DefaultSnackBar: {status: false, msgText: '', color: 'black', timeout: -1, closeAble: true},
    NotSignedInSnackBar: {status: true, msgText: 'Please sign in', color: 'red darken-4', timeout: -1, closeAble: true},
    ZeroListingsSnackBar: {status: true, msgText: 'Found (0) Listings', color: 'red darken-4', timeout: -1, closeAble: true},
    Countries: [
        /*
            { country: 'Afghanistan', code: '93'},
            { country: 'Albania', code: '355'},
            { country: 'Algeria', code: '213'},
            { country: 'American Samoa', code: '1684'},
            { country: 'Andorra', code: '376'},
            { country: 'Angola', code: '244'},
            { country: 'Anguilla', code: '1264'},
            { country: 'Antarctica', code: '672'},
            { country: 'Antigua and Barbuda', code: '1268'},
            { country: 'Argentina', code: '54'},
            { country: 'Armenia', code: '374'},
            { country: 'Aruba', code: '297'},
            */
            { country: 'Australia', code: '61'},
            /*
            { country: 'Austria', code: '43'},
            { country: 'Azerbaijan', code: '994'},
            { country: 'Bahamas', code: '1242'},
            { country: 'Bahrain', code: '973'},
            { country: 'Bangladesh', code: '880'},
            { country: 'Barbados', code: '1246'},
            { country: 'Belarus', code: '375'},
            { country: 'Belgium', code: '32'},
            { country: 'Belize', code: '501'},
            { country: 'Benin', code: '229'},
            { country: 'Bermuda', code: '1441'},
            { country: 'Bhutan', code: '975'},
            { country: 'Bolivia', code: '591'},
            { country: 'Bosnia and Herzegovina', code: '387'},
            { country: 'Botswana', code: '267'},
            { country: 'Brazil', code: '55'},
            { country: 'British Indian Ocean Territory', code: '246'},
            { country: 'British Virgin Islands', code: '1284'},
            { country: 'Brunei', code: '673'},
            { country: 'Bulgaria', code: '359'},
            { country: 'Burkina Faso', code: '226'},
            { country: 'Burundi', code: '257'},
            { country: 'Cambodia', code: '855'},
            { country: 'Cameroon', code: '237'},
            */
            { country: 'Canada/United States', code: '1'},
            /*
            { country: 'Cape Verde', code: '238'},
            { country: 'Cayman Islands', code: '1345'},
            { country: 'Central African Republic', code: '236'},
            { country: 'Chad', code: '235'},
            { country: 'Chile', code: '56'},
            { country: 'China', code: '86'},
            { country: 'Christmas Island', code: '61'},
            { country: 'Cocos Islands', code: '61'},
            { country: 'Colombia', code: '57'},
            { country: 'Comoros', code: '269'},
            { country: 'Cook Islands', code: '682'},
            { country: 'Costa Rica', code: '506'},
            { country: 'Croatia', code: '385'},
            { country: 'Cuba', code: '53'},
            { country: 'Curacao', code: '599'},
            { country: 'Cyprus', code: '357'},
            { country: 'Czech Republic', code: '420'},
            { country: 'Democratic Republic of the Congo', code: '243'},
            { country: 'Denmark', code: '45'},
            { country: 'Djibouti', code: '253'},
            { country: 'Dominica', code: '1767'},
            { country: 'Dominican Republic', code: '1809, 1829, 1849'},
            { country: 'East Timor', code: '670'},
            { country: 'Ecuador', code: '593'},
            { country: 'Egypt', code: '20'},
            { country: 'El Salvador', code: '503'},
            { country: 'Equatorial Guinea', code: '240'},
            { country: 'Eritrea', code: '291'},
            { country: 'Estonia', code: '372'},
            { country: 'Ethiopia', code: '251'},
            { country: 'Falkland Islands', code: '500'},
            { country: 'Faroe Islands', code: '298'},
            { country: 'Fiji', code: '679'},
            { country: 'Finland', code: '358'},
            { country: 'France', code: '33'},
            { country: 'French Polynesia', code: '689'},
            { country: 'Gabon', code: '241'},
            { country: 'Gambia', code: '220'},
            { country: 'Georgia', code: '995'},
            { country: 'Germany', code: '49'},
            { country: 'Ghana', code: '233'},
            { country: 'Gibraltar', code: '350'},
            { country: 'Greece', code: '30'},
            { country: 'Greenland', code: '299'},
            { country: 'Grenada', code: '1473'},
            { country: 'Guam', code: '1671'},
            { country: 'Guatemala', code: '502'},
            { country: 'Guernsey', code: '441481'},
            { country: 'Guinea', code: '224'},
            { country: 'GuineaBissau', code: '245'},
            { country: 'Guyana', code: '592'},
            { country: 'Haiti', code: '509'},
            { country: 'Honduras', code: '504'},
            { country: 'Hong Kong', code: '852'},
            { country: 'Hungary', code: '36'},
            { country: 'Iceland', code: '354'},
            { country: 'India', code: '91'},
            { country: 'Indonesia', code: '62'},
            { country: 'Iran', code: '98'},
            { country: 'Iraq', code: '964'},
            { country: 'Ireland', code: '353'},
            { country: 'Isle of Man', code: '441624'},
            { country: 'Israel', code: '972'},
            { country: 'Italy', code: '39'},
            { country: 'Ivory Coast', code: '225'},
            { country: 'Jamaica', code: '1876'},
            { country: 'Japan', code: '81'},
            { country: 'Jersey', code: '441534'},
            { country: 'Jordan', code: '962'},
            { country: 'Kazakhstan', code: '7'},
            { country: 'Kenya', code: '254'},
            { country: 'Kiribati', code: '686'},
            { country: 'Kosovo', code: '383'},
            { country: 'Kuwait', code: '965'},
            { country: 'Kyrgyzstan', code: '996'},
            { country: 'Laos', code: '856'},
            { country: 'Latvia', code: '371'},
            { country: 'Lebanon', code: '961'},
            { country: 'Lesotho', code: '266'},
            { country: 'Liberia', code: '231'},
            { country: 'Libya', code: '218'},
            { country: 'Liechtenstein', code: '423'},
            { country: 'Lithuania', code: '370'},
            { country: 'Luxembourg', code: '352'},
            { country: 'Macau', code: '853'},
            { country: 'Macedonia', code: '389'},
            { country: 'Madagascar', code: '261'},
            { country: 'Malawi', code: '265'},
            { country: 'Malaysia', code: '60'},
            { country: 'Maldives', code: '960'},
            { country: 'Mali', code: '223'},
            { country: 'Malta', code: '356'},
            { country: 'Marshall Islands', code: '692'},
            { country: 'Mauritania', code: '222'},
            { country: 'Mauritius', code: '230'},
            { country: 'Mayotte', code: '262'},
            { country: 'Mexico', code: '52'},
            { country: 'Micronesia', code: '691'},
            { country: 'Moldova', code: '373'},
            { country: 'Monaco', code: '377'},
            { country: 'Mongolia', code: '976'},
            { country: 'Montenegro', code: '382'},
            { country: 'Montserrat', code: '1664'},
            { country: 'Morocco', code: '212'},
            { country: 'Mozambique', code: '258'},
            { country: 'Myanmar', code: '95'},
            { country: 'Namibia', code: '264'},
            { country: 'Nauru', code: '674'},
            { country: 'Nepal', code: '977'},
            { country: 'Netherlands', code: '31'},
            { country: 'Netherlands Antilles', code: '599'},
            { country: 'New Caledonia', code: '687'},
            { country: 'New Zealand', code: '64'},
            { country: 'Nicaragua', code: '505'},
            { country: 'Niger', code: '227'},
            { country: 'Nigeria', code: '234'},
            { country: 'Niue', code: '683'},
            { country: 'North Korea', code: '850'},
            { country: 'Northern Mariana Islands', code: '1670'},
            { country: 'Norway', code: '47'},
            { country: 'Oman', code: '968'},
            */
            { country: 'Pakistan', code: '92'},
            /*
            { country: 'Palau', code: '680'},
            { country: 'Palestine', code: '970'},
            { country: 'Panama', code: '507'},
            { country: 'Papua New Guinea', code: '675'},
            { country: 'Paraguay', code: '595'},
            { country: 'Peru', code: '51'},
            { country: 'Philippines', code: '63'},
            { country: 'Pitcairn', code: '64'},
            { country: 'Poland', code: '48'},
            { country: 'Portugal', code: '351'},
            { country: 'Puerto Rico', code: '1787, 1939'},
            { country: 'Qatar', code: '974'},
            { country: 'Republic of the Congo', code: '242'},
            { country: 'Reunion', code: '262'},
            { country: 'Romania', code: '40'},
            { country: 'Russia', code: '7'},
            { country: 'Rwanda', code: '250'},
            { country: 'Saint Barthelemy', code: '590'},
            { country: 'Saint Helena', code: '290'},
            { country: 'Saint Kitts and Nevis', code: '1869'},
            { country: 'Saint Lucia', code: '1758'},
            { country: 'Saint Martin', code: '590'},
            { country: 'Saint Pierre and Miquelon', code: '508'},
            { country: 'Saint Vincent and the Grenadines', code: '1784'},
            { country: 'Samoa', code: '685'},
            { country: 'San Marino', code: '378'},
            { country: 'Sao Tome and Principe', code: '239'},
            */
            { country: 'Saudi Arabia', code: '966'},
            /*
            { country: 'Senegal', code: '221'},
            { country: 'Serbia', code: '381'},
            { country: 'Seychelles', code: '248'},
            { country: 'Sierra Leone', code: '232'},
            { country: 'Singapore', code: '65'},
            { country: 'Sint Maarten', code: '1721'},
            { country: 'Slovakia', code: '421'},
            { country: 'Slovenia', code: '386'},
            { country: 'Solomon Islands', code: '677'},
            { country: 'Somalia', code: '252'},
            { country: 'South Africa', code: '27'},
            { country: 'South Korea', code: '82'},
            { country: 'South Sudan', code: '211'},
            { country: 'Spain', code: '34'},
            { country: 'Sri Lanka', code: '94'},
            { country: 'Sudan', code: '249'},
            { country: 'Suriname', code: '597'},
            { country: 'Svalbard and Jan Mayen', code: '47'},
            { country: 'Swaziland', code: '268'},
            { country: 'Sweden', code: '46'},
            { country: 'Switzerland', code: '41'},
            { country: 'Syria', code: '963'},
            { country: 'Taiwan', code: '886'},
            { country: 'Tajikistan', code: '992'},
            { country: 'Tanzania', code: '255'},
            { country: 'Thailand', code: '66'},
            { country: 'Togo', code: '228'},
            { country: 'Tokelau', code: '690'},
            { country: 'Tonga', code: '676'},
            { country: 'Trinidad and Tobago', code: '1868'},
            { country: 'Tunisia', code: '216'},
            { country: 'Turkey', code: '90'},
            { country: 'Turkmenistan', code: '993'},
            { country: 'Turks and Caicos Islands', code: '1649'},
            { country: 'Tuvalu', code: '688'},
            { country: 'U.S. Virgin Islands', code: '1340'},
            { country: 'Uganda', code: '256'},
            { country: 'Ukraine', code: '380'},
            { country: 'United Arab Emirates', code: '971'},
            */
            { country: 'United Kingdom', code: '44'},
            { country: 'United States', code: '1'},
            /*
            { country: 'Uruguay', code: '598'},
            { country: 'Uzbekistan', code: '998'},
            { country: 'Vanuatu', code: '678'},
            { country: 'Vatican', code: '379'},
            { country: 'Venezuela', code: '58'},
            { country: 'Vietnam', code: '84'},
            { country: 'Wallis and Futuna', code: '681'},
            { country: 'Western Sahara', code: '212'},
            { country: 'Yemen', code: '967'},
            { country: 'Zambia', code: '260'},
            { country: 'Zimbabwe', code: '263'},
            */
        ],
    States: [
        { StateCode: "PJ", StateName: "Punjab" }
    ],
    Cities: [
        { CountryCode: "PK", CityCode: "LHE", CityName: "Lahore", Latitude: "31.595228", Longitude: "74.302232", State: "PJ" },
    ],
    Societies: [
        { CityCode: "LHE", SocietyDBCode: "DHA", SocietyCode: "DHA", SocietyName: "DHA Lahore", Latitude: "31.46555", Longitude: "74.42492"},
    ],
    Phases: [
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "01", PhaseCode: "dha-lahore-phase-1", PhaseName: "Ph 1", Latitude: "31.486122866331097", Longitude: "74.3850497606036"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "02", PhaseCode: "dha-lahore-phase-2", PhaseName: "Ph 2", Latitude:"31.48361110183615", Longitude:"74.40802234458717"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "03", PhaseCode: "dha-lahore-phase-3", PhaseName: "Ph 3", Latitude:"31.47841773563627", Longitude:"74.3790239095688"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "04", PhaseCode: "dha-lahore-phase-4", PhaseName: "Ph 4", Latitude: "31.469151582659226", Longitude: "74.39074194411661"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "05", PhaseCode: "dha-lahore-phase-5", PhaseName: "Ph 5", Latitude: "31.4576", Longitude: "74.3956"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "06", PhaseCode: "dha-lahore-phase-6", PhaseName: "Ph 6", Latitude: "31.4759", Longitude: "74.4595"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "07", PhaseCode: "dha-lahore-phase-7", PhaseName: "Ph 7", Latitude: "31.465348410672842", Longitude: "74.4891929626465"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "08", PhaseCode: "dha-lahore-phase-8", PhaseName: "Ph 8 Proper", Latitude: "31.487821121636433", Longitude: "74.44396018981935"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "81", PhaseCode: "dha-lahore-phase-81", PhaseName: "Ph 8 ParkView", Latitude: "31.519203882085268", Longitude: "74.42584186792375"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "82", PhaseCode: "dha-lahore-phase-82", PhaseName: "Ph 8 Air Avenue", Latitude: "31.511568982453586", Longitude: "74.43987250328065"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "8B", PhaseCode: "dha-lahore-phase-8B", PhaseName: "Ph 8 Broadway", Latitude: "31.50283118461112", Longitude: "74.43123042583467"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "8I", PhaseCode: "dha-lahore-phase-8I", PhaseName: "Ph 8 Z Ivy Green", Latitude: "31.498024018496373", Longitude: "74.50166523456575"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "09", PhaseCode: "dha-lahore-phase-9", PhaseName: "Ph 9 Town", Latitude: "31.440417155245495", Longitude: "74.44241523742677"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "91", PhaseCode: "dha-lahore-phase-91", PhaseName: "Ph 9 Prism", Latitude: "31.440417155245495", Longitude: "74.44241523742677"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "R1", PhaseCode: "dha-lahore-phase-R1", PhaseName: "Ph 11 Sector 1", Latitude:"31.386795700593353", Longitude:"74.2519596219063"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "R2", PhaseCode: "dha-lahore-phase-R2", PhaseName: "Ph 11 Sector 2", Latitude:"31.383370211562532", Longitude:"74.27837938070299"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "RH", PhaseCode: "dha-lahore-phase-RH", PhaseName: "Halloki", Latitude:"31.378599727530602", Longitude:"74.26954364847008"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "R4", PhaseCode: "dha-lahore-phase-R4", PhaseName: "Ph 11 Sector 4", Latitude:"31.386705187207404", Longitude:"74.28892636286038"},
        { CityCode: "LHE", SocietyCode: "DHA", PhaseDBCode: "12", PhaseCode: "dha-lahore-phase-12", PhaseName: "Ph 12 EME", Latitude: "31.432302083326", Longitude: "74.20703262090684"},
    ],
    Mouzas: [
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p5mrw", Type: "Residential", MouzaDBCode: "RW", MouzaName: "Roran Wala", PhaseID: "dha-lahore-phase-5" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p5mlv", Type: "Residential", MouzaDBCode: "LV", MouzaName: "Leel Village", PhaseID: "dha-lahore-phase-5" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p5mkw", Type: "Residential", MouzaDBCode: "KW", MouzaName: "Khazanchi Wala", PhaseID: "dha-lahore-phase-5" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p5mmw", Type: "Residential", MouzaDBCode: "MW", MouzaName: "Mana Wala", PhaseID: "dha-lahore-phase-5" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p5mrwc", Type: "Commercial", MouzaDBCode: "RWC", MouzaName: "Roran Wala", PhaseID: "dha-lahore-phase-5" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p5mlvc", Type: "Commercial", MouzaDBCode: "LVC", MouzaName: "Leel Village", PhaseID: "dha-lahore-phase-5" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p5mkwc", Type: "Commercial", MouzaDBCode: "KWC", MouzaName: "Khazanchi Wala", PhaseID: "dha-lahore-phase-5" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p5mmwc", Type: "Commercial", MouzaDBCode: "MWC", MouzaName: "Mana Wala", PhaseID: "dha-lahore-phase-5" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p6mld", Type: "Residential", MouzaDBCode: "LD", MouzaName: "Lidher", PhaseID: "dha-lahore-phase-6" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p6mls", Type: "Residential", MouzaDBCode: "LS", MouzaName: "Lehna Singh", PhaseID: "dha-lahore-phase-6" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p6mhs", Type: "Residential", MouzaDBCode: "HS", MouzaName: "Hera Singh Wala", PhaseID: "dha-lahore-phase-6" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p6mpg", Type: "Residential", MouzaDBCode: "PG", MouzaName: "Pangali", PhaseID: "dha-lahore-phase-6" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p6mldc", Type: "Commercial", MouzaDBCode: "LDC", MouzaName: "Lidher", PhaseID: "dha-lahore-phase-6" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p6mlsc", Type: "Commercial", MouzaDBCode: "LSC", MouzaName: "Lehna Singh", PhaseID: "dha-lahore-phase-6" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p6mhsc", Type: "Commercial", MouzaDBCode: "HSC", MouzaName: "Hera Singh Wala", PhaseID: "dha-lahore-phase-6" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p6mpgc", Type: "Commercial", MouzaDBCode: "PGC", MouzaName: "Pangali", PhaseID: "dha-lahore-phase-6" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p7mdc", Type: "Residential", MouzaDBCode: "DC", MouzaName: "Dera Chahal", PhaseID: "dha-lahore-phase-7" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p7mms", Type: "Residential", MouzaDBCode: "MS", MouzaName: "Motta Singh", PhaseID: "dha-lahore-phase-7" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p7mcd", Type: "Residential", MouzaDBCode: "CD", MouzaName: "Chak Dera", PhaseID: "dha-lahore-phase-7" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p7msp", Type: "Residential", MouzaDBCode: "SP", MouzaName: "Sangat Pura", PhaseID: "dha-lahore-phase-7" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p7mpk", Type: "Residential", MouzaDBCode: "PK", MouzaName: "Pangali Karbath", PhaseID: "dha-lahore-phase-7" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p7mdcc", Type: "Commercial", MouzaDBCode: "DCC", MouzaName: "Dera Chahal", PhaseID: "dha-lahore-phase-7" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p7mmsc", Type: "Commercial", MouzaDBCode: "MSC", MouzaName: "Motta Singh", PhaseID: "dha-lahore-phase-7" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p7mcdc", Type: "Commercial", MouzaDBCode: "CDC", MouzaName: "Chak Dera", PhaseID: "dha-lahore-phase-7" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p7mspc", Type: "Commercial", MouzaDBCode: "SPC", MouzaName: "Sangat Pura", PhaseID: "dha-lahore-phase-7" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p7mpkc", Type: "Commercial", MouzaDBCode: "PKC", MouzaName: "Pangali Karbath", PhaseID: "dha-lahore-phase-7" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p8mcw", Type: "Residential", MouzaDBCode: "CW", MouzaName: "Chacho Wali", PhaseID: "dha-lahore-phase-8" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p8mjd", Type: "Residential", MouzaDBCode: "JD", MouzaName: "Jindri", PhaseID: "dha-lahore-phase-8" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p8mjk", Type: "Residential", MouzaDBCode: "JK", MouzaName: "Jindra Kalan", PhaseID: "dha-lahore-phase-8" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p8mtb", Type: "Residential", MouzaDBCode: "TB", MouzaName: "Tibba", PhaseID: "dha-lahore-phase-8" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p8msp", Type: "Residential", MouzaDBCode: "SP", MouzaName: "Sehajpal", PhaseID: "dha-lahore-phase-8" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p8mcwc", Type: "Commercial", MouzaDBCode: "CWC", MouzaName: "Chacho Wali", PhaseID: "dha-lahore-phase-8" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p8mjdc", Type: "Commercial", MouzaDBCode: "JDC", MouzaName: "Jindri", PhaseID: "dha-lahore-phase-8" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p8mjkc", Type: "Commercial", MouzaDBCode: "JKC", MouzaName: "Jindra Kalan", PhaseID: "dha-lahore-phase-8" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p8mtbc", Type: "Commercial", MouzaDBCode: "TBC", MouzaName: "Tibba", PhaseID: "dha-lahore-phase-8" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p8mspc", Type: "Commercial", MouzaDBCode: "SPC", MouzaName: "Sehajpal", PhaseID: "dha-lahore-phase-8" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p81msp", Type: "Residential", MouzaDBCode: "SP", MouzaName: "Shivpur", PhaseID: "dha-lahore-phase-81" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p81mbw", Type: "Residential", MouzaDBCode: "BW", MouzaName: "Baoo Wala", PhaseID: "dha-lahore-phase-81" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p81mmp", Type: "Residential", MouzaDBCode: "MP", MouzaName: "Malakpur", PhaseID: "dha-lahore-phase-81" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p81mdp", Type: "Residential", MouzaDBCode: "DP", MouzaName: "Dher Pindi", PhaseID: "dha-lahore-phase-81" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p81mspc", Type: "Commercial", MouzaDBCode: "SPC", MouzaName: "Shivpur", PhaseID: "dha-lahore-phase-81" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p81mbwc", Type: "Commercial", MouzaDBCode: "BWC", MouzaName: "Baoo Wala", PhaseID: "dha-lahore-phase-81" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p81mmpc", Type: "Commercial", MouzaDBCode: "MPC", MouzaName: "Malakpur", PhaseID: "dha-lahore-phase-81" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p81mdpc", Type: "Commercial", MouzaDBCode: "DPC", MouzaName: "Dher Pindi", PhaseID: "dha-lahore-phase-81" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p82msp", Type: "Residential", MouzaDBCode: "SP", MouzaName: "Shivpur", PhaseID: "dha-lahore-phase-82" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p82mbw", Type: "Residential", MouzaDBCode: "BW", MouzaName: "Baoo Wala", PhaseID: "dha-lahore-phase-82" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p82mmp", Type: "Residential", MouzaDBCode: "MP", MouzaName: "Malakpur", PhaseID: "dha-lahore-phase-82" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p82mdp", Type: "Residential", MouzaDBCode: "DP", MouzaName: "Dher Pindi", PhaseID: "dha-lahore-phase-82" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p82mspc", Type: "Commercial", MouzaDBCode: "SPC", MouzaName: "Shivpur", PhaseID: "dha-lahore-phase-82" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p82mbwc", Type: "Commercial", MouzaDBCode: "BWC", MouzaName: "Baoo Wala", PhaseID: "dha-lahore-phase-82" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p82mmpc", Type: "Commercial", MouzaDBCode: "MPC", MouzaName: "Malakpur", PhaseID: "dha-lahore-phase-82" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p82mdpc", Type: "Commercial", MouzaDBCode: "DPC", MouzaName: "Dher Pindi", PhaseID: "dha-lahore-phase-82" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p91mhv", Type: "Residential", MouzaDBCode: "HV", MouzaName: "Harpalke Village", PhaseID: "dha-lahore-phase-91" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p91mdk", Type: "Residential", MouzaDBCode: "DK", MouzaName: "Dev Klan", PhaseID: "dha-lahore-phase-91" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p91mbv", Type: "Residential", MouzaDBCode: "BV", MouzaName: "Buller Village", PhaseID: "dha-lahore-phase-91" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p91mya", Type: "Residential", MouzaDBCode: "YA", MouzaName: "Youhannabad", PhaseID: "dha-lahore-phase-91" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p91mrw", Type: "Residential", MouzaDBCode: "RW", MouzaName: "Roran Wala", PhaseID: "dha-lahore-phase-91" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p91mhvc", Type: "Commercial", MouzaDBCode: "HVC", MouzaName: "Harpalke Village", PhaseID: "dha-lahore-phase-91" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p91mdkc", Type: "Commercial", MouzaDBCode: "DKC", MouzaName: "Dev Klan", PhaseID: "dha-lahore-phase-91" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p91mbvc", Type: "Commercial", MouzaDBCode: "BVC", MouzaName: "Buller Village", PhaseID: "dha-lahore-phase-91" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p91myac", Type: "Commercial", MouzaDBCode: "YAC", MouzaName: "Youhannabad", PhaseID: "dha-lahore-phase-91" },
        { CityCode: "LHE", SocietyCode: "DHA", MouzaID: "dha-lahore-mouza-p91mrwc", Type: "Commercial", MouzaDBCode: "RWC", MouzaName: "Roran Wala", PhaseID: "dha-lahore-phase-91" },
    ],
    Blocks: [
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1ba", Type: "Residential", BlockDBCode: "0A", BlockName: "A", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.486122866331097", Longitude:"74.3850497606036", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bb", Type: "Residential", BlockDBCode: "0B", BlockName: "B", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.484753799699856", Longitude:"74.38274896190707", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bc", Type: "Residential", BlockDBCode: "0C", BlockName: "C", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.480357441782086", Longitude:"74.38459914834311", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bd", Type: "Residential", BlockDBCode: "0D", BlockName: "D", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.485703950158506", Longitude:"74.39061695201418", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1be", Type: "Residential", BlockDBCode: "0E", BlockName: "E", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.482144892364087", Longitude:"74.39173811632374", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bj", Type: "Residential", BlockDBCode: "0J", BlockName: "J", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.47767109674665", Longitude:"74.39061588037477", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bk", Type: "Residential", BlockDBCode: "0K", BlockName: "K", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.475821859416627", Longitude:"74.39394825700221", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bl", Type: "Residential", BlockDBCode: "0L", BlockName: "L", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.483630315573674", Longitude:"74.39823979134418", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bm", Type: "Residential", BlockDBCode: "0M", BlockName: "M", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.484216335255606", Longitude:"74.40064787856501", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bn", Type: "Residential", BlockDBCode: "0N", BlockName: "N", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.488376008617283", Longitude:"74.3994403479519", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bp", Type: "Residential", BlockDBCode: "0P", BlockName: "P", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.486998620467", Longitude:"74.40498983844465", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bac", Type: "Commercial", BlockDBCode: "0AC", BlockName: "A Commercial", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.48833192995553", Longitude:"74.38199150585207", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bbc", Type: "Commercial", BlockDBCode: "0BC", BlockName: "B Commercial", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.484209669183695", Longitude: "74.38270336435382", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bcc", Type: "Commercial", BlockDBCode: "0CC", BlockName: "C Commercial", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.48097321855523", Longitude: "74.38751578232768", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bdc", Type: "Commercial", BlockDBCode: "0DC", BlockName: "D Commercial", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.486615209579853", Longitude: "74.38904893252585", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bec", Type: "Commercial", BlockDBCode: "0EC", BlockName: "E Commercial", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.479268676744564", Longitude: "74.39004778841536", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bfc", Type: "Commercial", BlockDBCode: "0FC", BlockName: "F Commercial", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.484722752009837", Longitude: "74.3922557831138", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bgc", Type: "Commercial", BlockDBCode: "0GC", BlockName: "G Commercial", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.48371677755314", Longitude: "74.39452385915503", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bhc", Type: "Commercial", BlockDBCode: "0HC", BlockName: "H Commercial", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.482513619861752", Longitude: "74.39552700549031", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bjc", Type: "Commercial", BlockDBCode: "0JC", BlockName: "J Commercial", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.477172419300757", Longitude: "74.38808655727372", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bkc", Type: "Commercial", BlockDBCode: "0KC", BlockName: "K Commercial", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.47090212436781", Longitude: "74.38952583040192", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1blc", Type: "Commercial", BlockDBCode: "0LC", BlockName: "L Commercial", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.483095986867976", Longitude: "74.39619970306923", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bmc", Type: "Commercial", BlockDBCode: "0MC", BlockName: "M Commercial", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.48095086426397", Longitude: "74.40303128992129", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bnc", Type: "Commercial", BlockDBCode: "0NC", BlockName: "N Commercial", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.485316084086033", Longitude: "74.39402925901051", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p1bpc", Type: "Commercial", BlockDBCode: "0PC", BlockName: "P Commercial", PhaseDBCode: "01", PhaseID: "dha-lahore-phase-1", Latitude:"31.48330916987534", Longitude: "74.40625691453988", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p2bq", Type: "Residential", BlockDBCode: "0Q", BlockName: "Q", PhaseDBCode: "02", PhaseID: "dha-lahore-phase-2", Latitude:"31.48361110183615", Longitude:"74.40802234458717", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p2br", Type: "Residential", BlockDBCode: "0R", BlockName: "R", PhaseDBCode: "02", PhaseID: "dha-lahore-phase-2", Latitude:"31.47883542989092", Longitude:"74.40339928845789", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p2bs", Type: "Residential", BlockDBCode: "0S", BlockName: "S", PhaseDBCode: "02", PhaseID: "dha-lahore-phase-2", Latitude:"31.472020354535566", Longitude:"74.40066388615482", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p2bt", Type: "Residential", BlockDBCode: "0T", BlockName: "T", PhaseDBCode: "02", PhaseID: "dha-lahore-phase-2", Latitude:"31.471670802197863", Longitude:"74.4034185144857", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p2bu", Type: "Residential", BlockDBCode: "0U", BlockName: "U", PhaseDBCode: "02", PhaseID: "dha-lahore-phase-2", Latitude:"31.474050363342506", Longitude:"74.40638029521325", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p2bv", Type: "Residential", BlockDBCode: "0V", BlockName: "V", PhaseDBCode: "02", PhaseID: "dha-lahore-phase-2", Latitude:"31.478656548528313", Longitude:"74.41020512625074", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p2bqc", Type: "Commercial", BlockDBCode: "0QC", BlockName: "Q Commercial", PhaseDBCode: "02", PhaseID: "dha-lahore-phase-2", Latitude:"31.482713537514876", Longitude:"74.40954476656317", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p2bsc", Type: "Commercial", BlockDBCode: "0SC", BlockName: "S Commercial", PhaseDBCode: "02", PhaseID: "dha-lahore-phase-2", Latitude:"31.470129803442973", Longitude:"74.39083367496097", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p2buc", Type: "Commercial", BlockDBCode: "0UC", BlockName: "U Commercial", PhaseDBCode: "02", PhaseID: "dha-lahore-phase-2", Latitude:"31.473993631380345", Longitude:"74.40768599493823", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p2bvc", Type: "Commercial", BlockDBCode: "0VC", BlockName: "V Commercial", PhaseDBCode: "02", PhaseID: "dha-lahore-phase-2", Latitude:"31.48077473206296", Longitude:"74.41226881776858", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p2bvcca", Type: "Commercial", BlockDBCode: "0CCA", BlockName: "CCA Commercial", PhaseDBCode: "02", PhaseID: "dha-lahore-phase-2", Latitude:"31.474263566888492", Longitude:"74.40290039774482", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p3bw", Type: "Residential", BlockDBCode: "0W", BlockName: "W", PhaseDBCode: "03", PhaseID: "dha-lahore-phase-3", Latitude:"31.47841773563627", Longitude:"74.3790239095688", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p3bx", Type: "Residential", BlockDBCode: "0X", BlockName: "X", PhaseDBCode: "03", PhaseID: "dha-lahore-phase-3", Latitude:"31.469253614563364", Longitude:"74.36986953020097", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p3bxx", Type: "Residential", BlockDBCode: "0XX", BlockName: "XX", PhaseDBCode: "03", PhaseID: "dha-lahore-phase-3", Latitude:"31.46892647133872", Longitude:"74.36215817928316", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p3by", Type: "Residential", BlockDBCode: "0Y", BlockName: "Y", PhaseDBCode: "03", PhaseID: "dha-lahore-phase-3", Latitude:"31.471397175096207", Longitude:"74.37863230705263", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p3bz", Type: "Residential", BlockDBCode: "0Z", BlockName: "Z", PhaseDBCode: "03", PhaseID: "dha-lahore-phase-3", Latitude:"31.473799185672014", Longitude:"74.38123136758806", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p3bwc", Type: "Commercial", BlockDBCode: "0WC", BlockName: "W Commercial", PhaseDBCode: "03", PhaseID: "dha-lahore-phase-3", Latitude:"31.47771318702696", Longitude:"74.3773314356804", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p3bxc", Type: "Commercial", BlockDBCode: "0XC", BlockName: "X Commercial", PhaseDBCode: "03", PhaseID: "dha-lahore-phase-3", Latitude:"31.466629573430605", Longitude:"74.37074661254884", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p3bxxc", Type: "Commercial", BlockDBCode: "0XXC", BlockName: "XX Commercial", PhaseDBCode: "03", PhaseID: "dha-lahore-phase-3", Latitude:"31.471017423019322", Longitude:"74.36286628246309", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p3byc", Type: "Commercial", BlockDBCode: "0YC", BlockName: "Y Commercial", PhaseDBCode: "03", PhaseID: "dha-lahore-phase-3", Latitude:"31.47278119824011", Longitude:"74.37794566154481", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p3bzc", Type: "Commercial", BlockDBCode: "0ZC", BlockName: "Z Commercial", PhaseDBCode: "03", PhaseID: "dha-lahore-phase-3", Latitude:"31.473218133527805", Longitude:"74.37878251075746", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p4ba", Type: "Residential", BlockDBCode: "0A", BlockName: "AA", PhaseDBCode: "04", PhaseID: "dha-lahore-phase-4", Latitude:"31.460283070565136", Longitude:"74.36865341656814", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p4bb", Type: "Residential", BlockDBCode: "0B", BlockName: "BB", PhaseDBCode: "04", PhaseID: "dha-lahore-phase-4", Latitude:"31.462327542595904", Longitude:"74.37292993076882", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0B.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p4bc", Type: "Residential", BlockDBCode: "0C", BlockName: "CC", PhaseDBCode: "04", PhaseID: "dha-lahore-phase-4", Latitude:"31.464365564091587", Longitude:"74.3804626461406", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0C.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p4bd", Type: "Residential", BlockDBCode: "0D", BlockName: "DD", PhaseDBCode: "04", PhaseID: "dha-lahore-phase-4", Latitude:"31.464706449907712", Longitude:"74.38266742236011", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0D.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p4be", Type: "Residential", BlockDBCode: "0E", BlockName: "EE", PhaseDBCode: "04", PhaseID: "dha-lahore-phase-4", Latitude:"31.466402168334824", Longitude:"74.38935416943424", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0E.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p4bf", Type: "Residential", BlockDBCode: "0F", BlockName: "FF", PhaseDBCode: "04", PhaseID: "dha-lahore-phase-4", Latitude:"31.460864660503205", Longitude:"74.38924205296645", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0F.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p4bg", Type: "Residential", BlockDBCode: "0G", BlockName: "GG", PhaseDBCode: "04", PhaseID: "dha-lahore-phase-4", Latitude:"31.456798945783163", Longitude:"74.3868548869432", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0G.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p4bh", Type: "Residential", BlockDBCode: "0H", BlockName: "HH", PhaseDBCode: "04", PhaseID: "dha-lahore-phase-4", Latitude:"31.4563358504497", Longitude:"74.39134544147237", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0H.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p4bj", Type: "Residential", BlockDBCode: "0J", BlockName: "JJ", PhaseDBCode: "04", PhaseID: "dha-lahore-phase-4", Latitude:"31.454890263974182", Longitude:"74.39264953169187", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0J.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p4bac", Type: "Commercial", BlockDBCode: "0AC", BlockName: "A Commercial", PhaseDBCode: "04", PhaseID: "dha-lahore-phase-4", Latitude:"31.461750994964323", Longitude:"74.3711092472404", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p4bcc", Type: "Commercial", BlockDBCode: "0CC", BlockName: "C Commercial", PhaseDBCode: "04", PhaseID: "dha-lahore-phase-4", Latitude:"31.46215503639567", Longitude:"74.3793157336313", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-0C.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p4bcca", Type: "Commercial", BlockDBCode: "CCA", BlockName: "CCA Commercial", PhaseDBCode: "04", PhaseID: "dha-lahore-phase-4", Latitude:"31.46195690514551", Longitude:"74.38446074724199", PolygonFileName:"Amaraat.Assets.Overlays.DHA-04-CCA.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-a", Type: "Residential", BlockDBCode: "0A", BlockName: "A", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.46715", Longitude:"74.42693", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-b", Type: "Residential", BlockDBCode: "0B", BlockName: "B", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.46612", Longitude:"74.4199", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0B.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-c", Type: "Residential", BlockDBCode: "0C", BlockName: "C", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.46135", Longitude:"74.41861", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0C.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-d", Type: "Residential", BlockDBCode: "0D", BlockName: "D", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.46768", Longitude:"74.41751", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0D.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-e", Type: "Residential", BlockDBCode: "0E", BlockName: "E", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.46133", Longitude:"74.41142", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0E.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-f", Type: "Residential", BlockDBCode: "0F", BlockName: "F", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.46638", Longitude:"74.40954", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0F.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-g", Type: "Residential", BlockDBCode: "0G", BlockName: "G", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.46775", Longitude:"74.40227", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0G.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-h", Type: "Residential", BlockDBCode: "0H", BlockName: "H", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.4585", Longitude:"74.40585", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0H.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-j", Type: "Residential", BlockDBCode: "0J", BlockName: "J", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.456608582768656", Longitude:"74.41782742738725", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0J.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-k", Type: "Residential", BlockDBCode: "0K", BlockName: "K", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.463140651571436", Longitude:"74.40329790115358", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0K.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-l", Type: "Residential", BlockDBCode: "0L", BlockName: "L", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.459745405422876", Longitude:"74.39769476652147", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0L.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-m", Type: "Residential", BlockDBCode: "0M", BlockName: "M", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.45316733772713", Longitude:"74.42276000976564", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0M.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p5bcca", Type: "Commercial", BlockDBCode: "CCA", BlockName: "CCA 1 Commercial", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.462660199688642", Longitude:"74.41584259271623", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-CCA.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p5bcc2", Type: "Commercial", BlockDBCode: "CC2", BlockName: "CCA 2 Commercial", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.469230737451944", Longitude:"74.41886812448503", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-CC2.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p5bac", Type: "Commercial", BlockDBCode: "AC", BlockName: "A Commercial", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.47023046200378", Longitude:"74.42624956369401", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p5bbc", Type: "Commercial", BlockDBCode: "BC", BlockName: "B Commercial", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.475819114467892", Longitude:"74.422464966774", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0B.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p5bmc", Type: "Commercial", BlockDBCode: "MC", BlockName: "M Commercial", PhaseDBCode: "05", PhaseID: "dha-lahore-phase-5", Latitude:"31.451224717377347", Longitude:"74.40788716077806", PolygonFileName:"Amaraat.Assets.Overlays.DHA-05-0M.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6ba", Type: "Residential", BlockDBCode: "0A", BlockName: "A", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.4671", Longitude:"74.4409", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6bb", Type: "Residential", BlockDBCode: "0B", BlockName: "B", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.4713", Longitude:"74.4466", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0B.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6bc", Type: "Residential", BlockDBCode: "0C", BlockName: "C", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.465375200842864", Longitude:"74.44739341735841", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0C.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6bd", Type: "Residential", BlockDBCode: "0D", BlockName: "D", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.460736079586116", Longitude:"74.45134162902833", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0D.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6be", Type: "Residential", BlockDBCode: "0E", BlockName: "E", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.457368202271702", Longitude:"74.45812225341798", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0E.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6bf", Type: "Residential", BlockDBCode: "0F", BlockName: "F", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.465787668450496", Longitude:"74.46043968200685", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0F.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6bg", Type: "Residential", BlockDBCode: "0G", BlockName: "G", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.471278216779982", Longitude:"74.45520401000978", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0G.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6bh", Type: "Residential", BlockDBCode: "0H", BlockName: "H", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.4749", Longitude:"74.4511", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0H.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6bj", Type: "Residential", BlockDBCode: "0J", BlockName: "J", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.47955003478807", Longitude:"74.45666313171388", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0J.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6bk", Type: "Residential", BlockDBCode: "0K", BlockName: "K", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.471278216779982", Longitude:"74.46421623229982", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0K.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6bl", Type: "Residential", BlockDBCode: "0L", BlockName: "L", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.480940799642518", Longitude:"74.46455955505373", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0L.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6bm", Type: "Residential", BlockDBCode: "0M", BlockName: "M", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.4793", Longitude:"74.474", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0M.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6bn", Type: "Residential", BlockDBCode: "0N", BlockName: "N", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.490602384772885", Longitude:"74.47031021118165", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0N.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6cbcca", Type: "Commercial", BlockDBCode: "0CCA", BlockName: "CCA 1 Commercial", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.468899", Longitude:"74.450912", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-CCA.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6cbcc2", Type: "Commercial", BlockDBCode: "0CC2", BlockName: "CCA 2 Commercial", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.452829", Longitude:"74.4522", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-CC2.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6cbmb", Type: "Commercial", BlockDBCode: "0MB", BlockName: "MB Commercial", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.48571682154873", Longitude:"74.45968598127367", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-MB.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6cbac", Type: "Commercial", BlockDBCode: "AC", BlockName: "A Commercial", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.467997652875788", Longitude:"74.43907320499422", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p6cblc", Type: "Commercial", BlockDBCode: "LC", BlockName: "L Commercial", PhaseDBCode: "06", PhaseID: "dha-lahore-phase-6", Latitude:"31.479646108024895", Longitude:"74.4684460759163", PolygonFileName:"Amaraat.Assets.Overlays.DHA-06-0L.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7bp", Type: "Residential", BlockDBCode: "0P", BlockName: "P", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.490566", Longitude:"74.476876", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-0P.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7bq", Type: "Residential", BlockDBCode: "0Q", BlockName: "Q", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.490566", Longitude:"74.476876", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-0Q.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7br", Type: "Residential", BlockDBCode: "0R", BlockName: "R", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.48403334739758", Longitude:"74.47400093078615", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-0R.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7bs", Type: "Residential", BlockDBCode: "0S", BlockName: "S", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.48127", Longitude:"74.482627", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-0S.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7bt", Type: "Residential", BlockDBCode: "0T", BlockName: "T", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.47589", Longitude:"74.495029", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-0T.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7bu", Type: "Residential", BlockDBCode: "0U", BlockName: "U", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.472303", Longitude:"74.494772", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-0U.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7bv", Type: "Residential", BlockDBCode: "0V", BlockName: "V", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.473035", Longitude:"74.480867", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-0V.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7bw", Type: "Residential", BlockDBCode: "0W", BlockName: "W", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.464616", Longitude:"74.480352" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7bx", Type: "Residential", BlockDBCode: "0X", BlockName: "X", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.464177", Longitude:"74.4946", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-0X.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7by", Type: "Residential", BlockDBCode: "0Y", BlockName: "Y", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.450412", Longitude:"74.493399", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-0Y.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7bz", Type: "Residential", BlockDBCode: "0Z", BlockName: "Z", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.458759", Longitude:"74.479494", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-0Z.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7cbcc1", Type: "Commercial", BlockDBCode: "0CC1", BlockName: "CCA 1 Commercial", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.488297", Longitude:"74.485974", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-CC1.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7cbcc2", Type: "Commercial", BlockDBCode: "0CC2", BlockName: "CCA 2 Commercial", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.449058", Longitude:"74.489923", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-CC2.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7cbcc3", Type: "Commercial", BlockDBCode: "0CC3", BlockName: "CCA 3 Commercial", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.465788", Longitude:"74.498377", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-CC3.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7cbcc4", Type: "Commercial", BlockDBCode: "0CC4", BlockName: "CCA 4 Commercial", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.483283", Longitude:"74.500952", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-CC4.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p7cbcc5", Type: "Commercial", BlockDBCode: "0CC5", BlockName: "CCA 5 Commercial", PhaseDBCode: "07", PhaseID: "dha-lahore-phase-7", Latitude:"31.478964", Longitude:"74.493742", PolygonFileName:"Amaraat.Assets.Overlays.DHA-07-CC5.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bs", Type: "Residential", BlockDBCode: "0S", BlockName: "S", PhaseDBCode: "08", PhaseID: "dha-lahore-phase-8", Latitude:"31.50362930577303", Longitude:"74.46773529052736", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0S.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bt", Type: "Residential", BlockDBCode: "0T", BlockName: "T", PhaseDBCode: "08", PhaseID: "dha-lahore-phase-8", Latitude:"31.48804069804861", Longitude:"74.4536590576172", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0T.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bu", Type: "Residential", BlockDBCode: "0U", BlockName: "U", PhaseDBCode: "08", PhaseID: "dha-lahore-phase-8", Latitude:"31.4941154410515", Longitude:"74.44164276123048", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0U.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bv", Type: "Residential", BlockDBCode: "0V", BlockName: "V", PhaseDBCode: "08", PhaseID: "dha-lahore-phase-8", Latitude:"31.49089514450278", Longitude:"74.4360637664795", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0V.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bw", Type: "Residential", BlockDBCode: "0W", BlockName: "W", PhaseDBCode: "08", PhaseID: "dha-lahore-phase-8", Latitude:"31.48262432944035", Longitude:"74.44533348083498", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0W.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bx", Type: "Residential", BlockDBCode: "0X", BlockName: "X", PhaseDBCode: "08", PhaseID: "dha-lahore-phase-8", Latitude:"31.477500448904213", Longitude:"74.43958282470705", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0X.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8by", Type: "Residential", BlockDBCode: "0Y", BlockName: "Y", PhaseDBCode: "08", PhaseID: "dha-lahore-phase-8", Latitude:"31.477134446701086", Longitude:"74.42979812622072", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0Y.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bziv1", Type: "Residential", BlockDBCode: "0ZIV1", BlockName: "Z-1", PhaseDBCode: "8I", PhaseID: "dha-lahore-phase-8I", Latitude:"31.52601737166929", Longitude:"74.48314994573595" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bziv2", Type: "Residential", BlockDBCode: "0ZIV2", BlockName: "Z-2", PhaseDBCode: "8I", PhaseID: "dha-lahore-phase-8I", Latitude:"31.52733199786461", Longitude:"74.49174910783769" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bziv3", Type: "Residential", BlockDBCode: "0ZIV3", BlockName: "Z-3", PhaseDBCode: "8I", PhaseID: "dha-lahore-phase-8I", Latitude:"31.521151950642725", Longitude:"74.49567854404451" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bziv4", Type: "Residential", BlockDBCode: "0ZIV4", BlockName: "Z-4", PhaseDBCode: "8I", PhaseID: "dha-lahore-phase-8I", Latitude:"31.5029592503568", Longitude:"74.50310826301576" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bziv5", Type: "Residential", BlockDBCode: "0ZIV5", BlockName: "Z-5", PhaseDBCode: "8I", PhaseID: "dha-lahore-phase-8I", Latitude:"31.4917391107831", Longitude:"74.49826151132585" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bziv6", Type: "Residential", BlockDBCode: "0ZIV6", BlockName: "Z-6", PhaseDBCode: "8I", PhaseID: "dha-lahore-phase-8I", Latitude:"31.506039636056396", Longitude:"74.48685407638551" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bziv1c", Type: "Commercial", BlockDBCode: "0ZIV1C", BlockName: "Z-1 Commercial", PhaseDBCode: "8I", PhaseID: "dha-lahore-phase-8I", Latitude:"31.518046911345333", Longitude:"74.48432207107545" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bziv2c", Type: "Commercial", BlockDBCode: "0ZIV2C", BlockName: "Z-2 Commercial", PhaseDBCode: "8I", PhaseID: "dha-lahore-phase-8I", Latitude:"31.51820696743996", Longitude:"74.48981255292894" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8bziv6c", Type: "Commercial", BlockDBCode: "0ZIV6C", BlockName: "Z-6 Commercial", PhaseDBCode: "8I", PhaseID: "dha-lahore-phase-8I", Latitude:"31.510999598367146", Longitude:"74.48059648275377" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8cbcca", Type: "Commercial", BlockDBCode: "0CCA", BlockName: "CCA A Commercial", PhaseDBCode: "81", PhaseID: "dha-lahore-phase-81", Latitude:"31.521081", Longitude:"74.438853", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-CCA.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8cbcc1", Type: "Commercial", BlockDBCode: "0CC1", BlockName: "CCA 1 Commercial", PhaseDBCode: "08", PhaseID: "dha-lahore-phase-8", Latitude:"31.491407", Longitude:"74.44793", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-CC1.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8cbcc2", Type: "Commercial", BlockDBCode: "0CC2", BlockName: "CCA 2 Commercial", PhaseDBCode: "08", PhaseID: "dha-lahore-phase-8", Latitude:"31.485808", Longitude:"74.440312", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-CC2.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8Bb0A", Type: "Commercial", BlockDBCode: "0A", BlockName: "A Commercial", PhaseDBCode: "8B", PhaseID: "dha-lahore-phase-8B", Latitude:"31.503656748213306", Longitude:"74.42759603261949", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-BWA.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8Bb0B", Type: "Commercial", BlockDBCode: "0B", BlockName: "B Commercial", PhaseDBCode: "8B", PhaseID: "dha-lahore-phase-8B", Latitude:"31.50268939733092", Longitude:"74.4348031282425", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-BWB.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8Bb0C", Type: "Commercial", BlockDBCode: "0C", BlockName: "C Commercial", PhaseDBCode: "8B", PhaseID: "dha-lahore-phase-8B", Latitude:"31.50051224951173", Longitude:"74.43174540996553", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-BWC.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p8Bb0D", Type: "Commercial", BlockDBCode: "0D", BlockName: "D Commercial", PhaseDBCode: "8B", PhaseID: "dha-lahore-phase-8B", Latitude:"31.501550517505656", Longitude:"74.42765772342683", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-BWD.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p81ba", Type: "Residential", BlockDBCode: "0A", BlockName: "A", PhaseDBCode: "81", PhaseID: "dha-lahore-phase-81", Latitude:"31.519203882085268", Longitude:"74.42584186792375"},
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p81bb", Type: "Residential", BlockDBCode: "0B", BlockName: "B", PhaseDBCode: "81", PhaseID: "dha-lahore-phase-81", Latitude:"31.520008722845585", Longitude:"74.43340301513673" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p81bd", Type: "Residential", BlockDBCode: "0D", BlockName: "D", PhaseDBCode: "81", PhaseID: "dha-lahore-phase-81", Latitude:"31.52444436853269", Longitude:"74.4412672519684", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0D.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p81be", Type: "Residential", BlockDBCode: "0E", BlockName: "E", PhaseDBCode: "81", PhaseID: "dha-lahore-phase-81", Latitude:"31.525749871551785", Longitude:"74.43187952041627", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0E.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p81bf", Type: "Residential", BlockDBCode: "0F", BlockName: "F", PhaseDBCode: "81", PhaseID: "dha-lahore-phase-81", Latitude:"31.52405568635065", Longitude:"74.42636221647264", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0F.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p81bg", Type: "Residential", BlockDBCode: "0G", BlockName: "G", PhaseDBCode: "81", PhaseID: "dha-lahore-phase-81", Latitude:"31.527889851040708", Longitude:"74.42983835935594", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0G.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p81bh", Type: "Residential", BlockDBCode: "0H", BlockName: "H", PhaseDBCode: "81", PhaseID: "dha-lahore-phase-81", Latitude:"31.528079611812426", Longitude:"74.43299531936647", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0H.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p81bj", Type: "Residential", BlockDBCode: "0J", BlockName: "J", PhaseDBCode: "81", PhaseID: "dha-lahore-phase-81", Latitude:"31.527398300260813", Longitude:"74.439754486084", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0J.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p81bk", Type: "Residential", BlockDBCode: "0K", BlockName: "K", PhaseDBCode: "81", PhaseID: "dha-lahore-phase-81", Latitude:"31.5317444339775", Longitude:"74.43680405616762", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0K.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p82bl", Type: "Residential", BlockDBCode: "0L", BlockName: "L", PhaseDBCode: "82", PhaseID: "dha-lahore-phase-82", Latitude:"31.511568982453586", Longitude:"74.43987250328065" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p82bm", Type: "Residential", BlockDBCode: "0M", BlockName: "M", PhaseDBCode: "82", PhaseID: "dha-lahore-phase-82", Latitude:"31.50863284420482", Longitude:"74.43739950656892", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0M.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p82bn", Type: "Residential", BlockDBCode: "0N", BlockName: "N", PhaseDBCode: "82", PhaseID: "dha-lahore-phase-82", Latitude:"31.505758357831784", Longitude:"74.44287389516832", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0N.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p82bp", Type: "Residential", BlockDBCode: "0P", BlockName: "P", PhaseDBCode: "82", PhaseID: "dha-lahore-phase-82", Latitude:"31.51139976826808", Longitude:"74.4439682364464", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0P.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p82bq", Type: "Residential", BlockDBCode: "0Q", BlockName: "Q", PhaseDBCode: "82", PhaseID: "dha-lahore-phase-82", Latitude:"31.50425590615118", Longitude:"74.44626957178117", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0Q.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p82br", Type: "Residential", BlockDBCode: "0R", BlockName: "R", PhaseDBCode: "82", PhaseID: "dha-lahore-phase-82", Latitude:"31.503418913463324", Longitude:"74.44293290376665", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0R.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p82blc", Type: "Commercial", BlockDBCode: "LC", BlockName: "L Commercial", PhaseDBCode: "82", PhaseID: "dha-lahore-phase-82", Latitude:"31.511049905534577", Longitude:"74.43673163652421" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p82bmc", Type: "Commercial", BlockDBCode: "MC", BlockName: "M Commercial", PhaseDBCode: "82", PhaseID: "dha-lahore-phase-82", Latitude:"31.510073484310855", Longitude:"74.43679869174959", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0M.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p82bnc", Type: "Commercial", BlockDBCode: "NC", BlockName: "N Commercial", PhaseDBCode: "82", PhaseID: "dha-lahore-phase-82", Latitude:"31.50735225657999", Longitude:"74.44204509258272", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0N.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p82bpc", Type: "Commercial", BlockDBCode: "PC", BlockName: "P Commercial", PhaseDBCode: "82", PhaseID: "dha-lahore-phase-82", Latitude:"31.507832478995354", Longitude:"74.44305360317232", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0P.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p82bqc", Type: "Commercial", BlockDBCode: "QC", BlockName: "Q Commercial", PhaseDBCode: "82", PhaseID: "dha-lahore-phase-82", Latitude:"31.503405192209293", Longitude:"74.44565266370775", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0Q.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p82brc", Type: "Commercial", BlockDBCode: "RC", BlockName: "R Commercial", PhaseDBCode: "82", PhaseID: "dha-lahore-phase-82", Latitude:"31.50245156011995", Longitude:"74.44406211376192", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0R.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p9ba", Type: "Residential", BlockDBCode: "0A", BlockName: "A", PhaseDBCode: "09", PhaseID: "dha-lahore-phase-9", Latitude:"31.444554518756835", Longitude:"74.44185733795167", PolygonFileName:"Amaraat.Assets.Overlays.DHA-09-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p9bb", Type: "Residential", BlockDBCode: "0B", BlockName: "B", PhaseDBCode: "09", PhaseID: "dha-lahore-phase-9", Latitude:"31.441259", Longitude:"74.443746", PolygonFileName:"Amaraat.Assets.Overlays.DHA-09-0B.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p9bc", Type: "Residential", BlockDBCode: "0C", BlockName: "C", PhaseDBCode: "09", PhaseID: "dha-lahore-phase-9", Latitude:"31.438293", Longitude:"74.434605", PolygonFileName:"Amaraat.Assets.Overlays.DHA-09-0C.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p9bd", Type: "Residential", BlockDBCode: "0D", BlockName: "D", PhaseDBCode: "09", PhaseID: "dha-lahore-phase-9", Latitude:"31.434815", Longitude:"74.442458", PolygonFileName:"Amaraat.Assets.Overlays.DHA-09-0D.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p9be", Type: "Residential", BlockDBCode: "0E", BlockName: "E", PhaseDBCode: "09", PhaseID: "dha-lahore-phase-9", Latitude:"31.449387", Longitude:"74.445677", PolygonFileName:"Amaraat.Assets.Overlays.DHA-08-0E.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p9bEC", Type: "Commercial", BlockDBCode: "EC", BlockName: "E Commercial", PhaseDBCode: "09", PhaseID: "dha-lahore-phase-9", Latitude:"31.45017902637588", Longitude:"74.44702327251436", PolygonFileName:"Amaraat.Assets.Overlays.DHA-09-0E.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p9cbcca", Type: "Commercial", BlockDBCode: "0CCA", BlockName: "CCA Commercial", PhaseDBCode: "09", PhaseID: "dha-lahore-phase-9", Latitude:"31.442065", Longitude:"74.440827", PolygonFileName:"Amaraat.Assets.Overlays.DHA-09-CCA.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91ba", Type: "Residential", BlockDBCode: "0A", BlockName: "A", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.43077785439444", Longitude:"74.43124651908876" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bb", Type: "Residential", BlockDBCode: "0B", BlockName: "B", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.441202078402934", Longitude:"74.42499697208406" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bc", Type: "Residential", BlockDBCode: "0C", BlockName: "C", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.432798711123606", Longitude:"74.41939920186998" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bd", Type: "Residential", BlockDBCode: "0D", BlockName: "D", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.4217647150102", Longitude:"74.41913098096849" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91be", Type: "Residential", BlockDBCode: "0E", BlockName: "E", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.418985984900242", Longitude:"74.4086328148842" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bf", Type: "Residential", BlockDBCode: "0F", BlockName: "F", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.42373083427333", Longitude:"74.39965546131135" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bg", Type: "Residential", BlockDBCode: "0G", BlockName: "G", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.416369691770285", Longitude:"74.3941166996956" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bh", Type: "Residential", BlockDBCode: "0H", BlockName: "H", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.418173408232494", Longitude:"74.38619613647462" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bj", Type: "Residential", BlockDBCode: "0J", BlockName: "J", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.420716406402104", Longitude:"74.37825143337251" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bk", Type: "Residential", BlockDBCode: "0K", BlockName: "K", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.40602054162912", Longitude:"74.38565433025362" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bl", Type: "Residential", BlockDBCode: "0L", BlockName: "L", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.40952761442835", Longitude:"74.39826339483263" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bm", Type: "Residential", BlockDBCode: "0M", BlockName: "M", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.410452434239897", Longitude:"74.40750360488893" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bn", Type: "Residential", BlockDBCode: "0N", BlockName: "N", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.41182133396117", Longitude:"74.42033797502519" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bp", Type: "Residential", BlockDBCode: "0P", BlockName: "P", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.426818405192915", Longitude:"74.43747997283937" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bq", Type: "Residential", BlockDBCode: "0Q", BlockName: "Q", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.427328794448513", Longitude:"74.4376838207245" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91br", Type: "Residential", BlockDBCode: "0R", BlockName: "R", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.424953057601673", Longitude:"74.45122092962266" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91b0ac", Type: "Commercial", BlockDBCode: "0AC", BlockName: "A Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.426303435639575", Longitude:"74.42751556634904" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91b0cc", Type: "Commercial", BlockDBCode: "0CC", BlockName: "C Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.42926962149503", Longitude:"74.41650778055192" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91b0dc", Type: "Commercial", BlockDBCode: "0DC", BlockName: "D Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.41404861473418", Longitude:"74.42004293203355" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91b0ec", Type: "Commercial", BlockDBCode: "0EC", BlockName: "E Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.418214609472408", Longitude:"74.40307527780534" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91b0fc", Type: "Commercial", BlockDBCode: "0FC", BlockName: "F Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.419446060399345", Longitude:"74.39507693052293" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91b0hc", Type: "Commercial", BlockDBCode: "0HC", BlockName: "H Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.41391584922257", Longitude:"74.38488185405733" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91b0jc", Type: "Commercial", BlockDBCode: "0JC", BlockName: "J Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.4125515582524", Longitude:"74.37977761030199" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91b0kc", Type: "Commercial", BlockDBCode: "0KC", BlockName: "K Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.409092671266425", Longitude:"74.38590914011003" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91b0lc", Type: "Commercial", BlockDBCode: "0LC", BlockName: "L Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.405885475499407", Longitude:"74.39193874597551" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91b0mc", Type: "Commercial", BlockDBCode: "0MC", BlockName: "M Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.409099538805688", Longitude:"74.40777182579042" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91b0nc", Type: "Commercial", BlockDBCode: "0NC", BlockName: "N Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.402069204683446", Longitude:"74.41306650638582" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91b0qc", Type: "Commercial", BlockDBCode: "0QC", BlockName: "Q Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.42605396048349", Longitude:"74.43583577871324" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91b0rc", Type: "Commercial", BlockDBCode: "0RC", BlockName: "R Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.418924183541872", Longitude:"74.4431394338608" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91b8mc", Type: "Commercial", BlockDBCode: "8MC", BlockName: "8-Marla Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.41680460119706", Longitude:"74.44996833801271" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bssc", Type: "Commercial", BlockDBCode: "SSC", BlockName: "SEC SHOP Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.419221745263783", Longitude:"74.45049136877061" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bovc", Type: "Commercial", BlockDBCode: "OVC", BlockName: "Oval Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.409697012798503", Longitude:"74.41097974777223" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bpc", Type: "Commercial", BlockDBCode: "0PC", BlockName: "Zone-1 Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.428473154878862", Longitude:"74.42896932363512" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p91bZ2", Type: "Commercial", BlockDBCode: "Z2C", BlockName: "Zone-2 Commercial", PhaseDBCode: "91", PhaseID: "dha-lahore-phase-91", Latitude:"31.423550017109932", Longitude:"74.41238790750505" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perba", Type: "Residential", BlockDBCode: "0A", BlockName: "A", PhaseDBCode: "R1", PhaseID: "dha-lahore-phase-R1", Latitude:"31.388300036974893", Longitude:"74.24831449985506" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbb", Type: "Residential", BlockDBCode: "0B", BlockName: "B", PhaseDBCode: "R1", PhaseID: "dha-lahore-phase-R1", Latitude:"31.391436855718094", Longitude:"74.25348848104478" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbc", Type: "Residential", BlockDBCode: "0C", BlockName: "C", PhaseDBCode: "R1", PhaseID: "dha-lahore-phase-R1", Latitude:"31.386795700593353", Longitude:"74.2519596219063" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbd", Type: "Residential", BlockDBCode: "0D", BlockName: "D", PhaseDBCode: "R1", PhaseID: "dha-lahore-phase-R1", Latitude:"31.39011574118059", Longitude:"74.25830304622652" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbe", Type: "Residential", BlockDBCode: "0E", BlockName: "E", PhaseDBCode: "R1", PhaseID: "dha-lahore-phase-R1", Latitude:"31.38660565333908", Longitude:"74.25652205944063" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbf", Type: "Residential", BlockDBCode: "0F", BlockName: "F", PhaseDBCode: "R2", PhaseID: "dha-lahore-phase-R2", Latitude:"31.38541727684754", Longitude:"74.2723122239113" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbg", Type: "Residential", BlockDBCode: "0G", BlockName: "G", PhaseDBCode: "R2", PhaseID: "dha-lahore-phase-R2", Latitude:"31.387123130502793", Longitude:"74.27500784397127" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbh", Type: "Residential", BlockDBCode: "0H", BlockName: "H", PhaseDBCode: "R2", PhaseID: "dha-lahore-phase-R2", Latitude:"31.3828366849811", Longitude:"74.27617460489274" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbj", Type: "Residential", BlockDBCode: "0J", BlockName: "J", PhaseDBCode: "R2", PhaseID: "dha-lahore-phase-R2", Latitude:"31.381872665747885", Longitude:"74.2745625972748" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbk", Type: "Residential", BlockDBCode: "0K", BlockName: "K", PhaseDBCode: "R2", PhaseID: "dha-lahore-phase-R2", Latitude:"31.383370211562532", Longitude:"74.27837938070299" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbl", Type: "Residential", BlockDBCode: "0L", BlockName: "L", PhaseDBCode: "R2", PhaseID: "dha-lahore-phase-R2", Latitude:"31.385021148008803", Longitude:"74.2779850959778" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbm", Type: "Residential", BlockDBCode: "0M", BlockName: "M", PhaseDBCode: "R2", PhaseID: "dha-lahore-phase-R2", Latitude:"31.384622727722732", Longitude:"74.27069753408433" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbn", Type: "Residential", BlockDBCode: "0N", BlockName: "N", PhaseDBCode: "R2", PhaseID: "dha-lahore-phase-R2", Latitude:"31.385458492468906", Longitude:"74.26687806844713" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbp", Type: "Residential", BlockDBCode: "0P", BlockName: "P", PhaseDBCode: "R2", PhaseID: "dha-lahore-phase-R2", Latitude:"31.38741392394076", Longitude:"74.26362723112108" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-prhba", Type: "Residential", BlockDBCode: "0A", BlockName: "A", PhaseDBCode: "RH", PhaseID: "dha-lahore-phase-RH", Latitude:"31.38158936447534", Longitude:"74.2713112243746" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-prhbb", Type: "Residential", BlockDBCode: "0B", BlockName: "B", PhaseDBCode: "RH", PhaseID: "dha-lahore-phase-RH", Latitude:"31.37773271037892", Longitude:"74.27224409647353" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-prhbac", Type: "Commercial", BlockDBCode: "AC", BlockName: "A Commercial", PhaseDBCode: "RH", PhaseID: "dha-lahore-phase-RH", Latitude:"31.380247678830816", Longitude:"74.27152472749187" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-pr4bcca3", Type: "Commercial", BlockDBCode: "CCA3", BlockName: "CCA-3 Commercial", PhaseDBCode: "R4", PhaseID: "dha-lahore-phase-R4", Latitude:"31.391943841171656", Longitude:"74.29859465340996" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-pr4bcca4", Type: "Commercial", BlockDBCode: "CCA4", BlockName: "CCA-4 Commercial", PhaseDBCode: "R4", PhaseID: "dha-lahore-phase-R4", Latitude:"31.38923633139081", Longitude:"74.28847414264966" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-pr4bsc", Type: "Commercial", BlockDBCode: "4SC", BlockName: "S Commercial", PhaseDBCode: "R4", PhaseID: "dha-lahore-phase-R4", Latitude:"31.384915541771868", Longitude:"74.29487979416992" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-pr4bq", Type: "Residential", BlockDBCode: "4Q", BlockName: "Q", PhaseDBCode: "R4", PhaseID: "dha-lahore-phase-R4", Latitude:"31.392529729390862", Longitude:"74.29957097765511" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-pr4br", Type: "Residential", BlockDBCode: "4R", BlockName: "R", PhaseDBCode: "R4", PhaseID: "dha-lahore-phase-R4", Latitude:"31.386690472806293", Longitude:"74.29092675447465" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-pr4bs", Type: "Residential", BlockDBCode: "4S", BlockName: "S", PhaseDBCode: "R4", PhaseID: "dha-lahore-phase-R4", Latitude:"31.381036124588263", Longitude:"74.29420334094175" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbfc", Type: "Commercial", BlockDBCode: "FC", BlockName: "F Commercial", PhaseDBCode: "ER", PhaseID: "dha-lahore-phase-ER", Latitude:"31.386827756718823", Longitude:"74.27300155162813" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbhc", Type: "Commercial", BlockDBCode: "HC", BlockName: "H Commercial", PhaseDBCode: "ER", PhaseID: "dha-lahore-phase-ER", Latitude:"31.382909959197566", Longitude:"74.27556037902833" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbmc", Type: "Commercial", BlockDBCode: "MC", BlockName: "M Commercial", PhaseDBCode: "ER", PhaseID: "dha-lahore-phase-ER", Latitude:"31.387684109162777", Longitude:"74.26889777183534" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-perbpc", Type: "Commercial", BlockDBCode: "PC", BlockName: "P Commercial", PhaseDBCode: "ER", PhaseID: "dha-lahore-phase-ER", Latitude:"31.388625171960367", Longitude:"74.26088064908983" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12ba", Type: "Residential", BlockDBCode: "0A", BlockName: "A", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.43216018918631", Longitude:"74.20482248067857", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-0A.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12bb", Type: "Residential", BlockDBCode: "0B", BlockName: "B", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.430979255433915", Longitude:"74.20762270689012", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-0B.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12bc", Type: "Residential", BlockDBCode: "0C", BlockName: "C", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.429283353618743", Longitude:"74.2153099179268", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-0C.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12bd", Type: "Residential", BlockDBCode: "0D", BlockName: "D", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.432036603792877", Longitude:"74.21936541795732", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-0D.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12be", Type: "Residential", BlockDBCode: "0E", BlockName: "E", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.440735244431004", Longitude:"74.21140998601915", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-0E.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12bf", Type: "Residential", BlockDBCode: "0F", BlockName: "F", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.44417923446725", Longitude:"74.21508997678758", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-0F.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12bg", Type: "Residential", BlockDBCode: "0G", BlockName: "G", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.446623132009922", Longitude:"74.21803236007692", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-0G.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12bh", Type: "Residential", BlockDBCode: "0H", BlockName: "H", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.450975308548603", Longitude:"74.21219050884248", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-0H.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12bj", Type: "Residential", BlockDBCode: "0J", BlockName: "J", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.44646752994662", Longitude:"74.21163260936738", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-0J.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12bbc", Type: "Commercial", BlockDBCode: "0BC", BlockName: "B Commercial", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.428074919033588", Longitude:"74.21035856008531", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-0B.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12bcc", Type: "Commercial", BlockDBCode: "0CC", BlockName: "C Commercial", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.43130424256253", Longitude:"74.2146983742714", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-0C.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12bcca", Type: "Commercial", BlockDBCode: "0CCA", BlockName: "CC-A Commercial", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.43510559284917", Longitude:"74.21434432268144", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-CCA.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12bec", Type: "Commercial", BlockDBCode: "0EC", BlockName: "E Commercial", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.43797538432221", Longitude:"74.21300590038301", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-0E.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12bhc", Type: "Commercial", BlockDBCode: "0HC", BlockName: "H Commercial", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.447234096435622", Longitude:"74.21519190073015", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-0H.json" },
        { CityCode: "LHE", SocietyCode: "DHA", BlockID: "dha-lahore-block-p12bjc", Type: "Commercial", BlockDBCode: "0JC", BlockName: "J Commercial", PhaseDBCode: "12", PhaseID: "dha-lahore-phase-12", Latitude:"31.44779242748291", Longitude:"74.21022176742555", PolygonFileName:"Amaraat.Assets.Overlays.DHA-12-0J.json" },
    ],
    WishTypes: ['Private', 'Agency/Agent', 'Public']
}