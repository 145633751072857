<template>
<div class="mx-2">
    <v-card class="my-2" @click="tapped">
        <v-skeleton-loader type="list-item-avatar-three-line" class="mx-auto" v-if="loading"></v-skeleton-loader>
        <div v-else>
            <v-card-text v-if="!error">
                <div v-if="!$vuetify.breakpoint.mobile">
                    <v-row>
                        <v-col md="9">
                            <AgencyAgent :agency="agency" :agent="agent"/>
                            <div class="pt-5"></div>
                            <Address :data="address" :type="'listing'" :listingType="listingType" :propertyType="propertyType" :plots="plots"/>
                        </v-col>
                        <v-col md="3" class="text-center">
                            <div class="largerFont font-weight-bold primary--text">{{price}}</div>
                            <v-divider class="my-2"/>
                            <div class="largerFont font-weight-bold amber--text text--darken-1">{{area}}</div>
                        </v-col>
                    </v-row>
                    <v-row class="px-3 my-3">
                        <v-chip color="amber">{{index}}</v-chip>
                        <v-chip class="ml-1">{{timestamp}}</v-chip>
                        <v-spacer/>
                        <Features :data="features" :status="status"/>
                    </v-row>
                </div>
                <div v-else>
                    <v-row class="px-3 mb-3">
                        <v-chip color="amber" small>{{index}}</v-chip>
                        <v-chip class="ml-1" small>{{timestamp}}</v-chip>
                    </v-row>
                    <v-row>
                        <AgencyAgent :agency="agency" :agent="agent"/>
                        <div class="pt-5"></div>
                        <Address :data="address" :type="'listing'" :listingType="listingType" :propertyType="propertyType" :plots="plots"/>
                    </v-row>
                    <v-row>
                        <v-chip color="amber">{{price}}</v-chip>
                        <v-spacer/>
                        <v-chip>{{area}}</v-chip>
                    </v-row>
                    <v-row class="px-3 my-3">
                        <v-spacer/>
                        <Features :data="features" :status="status"/>
                    </v-row>
                </div>
            </v-card-text>
            <v-card-text v-else>
                <div>Error, Please check back later</div>
            </v-card-text>
        </div>
    </v-card>
    <v-dialog v-model="dialog" scrollable max-width="500px" :fullscreen="fullscreen">
        <v-card>
            <v-card-title class="primary">
                <div class="text-center white--text" style="width: 100%;">
                    <Address :data="address" class="mb-2" :type="'listing'" :listingType="listingType" :propertyType="propertyType" :plots="plots"/>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :style="otherSellers.length > 0 ? 'height: 90vh' : ''" class="pa-0">
                <v-list>
                    <v-list-item @click="showAgencyProfile">
                        <v-list-item-avatar class="borderProfile">
                            <v-img :src="agency.photoURL"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{agency.name}}</v-list-item-title>
                            <v-list-item-subtitle>Agency</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon>
                                <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider inset></v-divider>
                    <v-list-item @click="showAgentProfile">
                        <v-list-item-avatar class="borderProfile">
                            <v-img :src="agent.photoURL"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{agent.name}}</v-list-item-title>
                            <v-list-item-subtitle>
                                <div>Agent</div>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon>
                                <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <div class="px-5">
                        <phone-number-reveal :agentID="agent.id"/>
                    </div>
                    <v-divider/>
                    <v-list-item @click="moreListings">
                        <v-list-item-avatar>
                            <v-icon>mdi-map-marker-multiple</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>More listings in the same area</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon>
                                <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider/>
                    <div v-if="otherSellers.length > 0">
                        <v-subheader class="primary--text font-weight-bold">Other Sellers</v-subheader>
                        <Listing v-for="L in otherSellers" :key="L.id" :item="L" :index="L.index"/>
                    </div>
                    <div v-else>
                        <v-subheader class="primary--text font-weight-bold">No Other Sellers</v-subheader>
                    </div>
                </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
            <v-btn color="primary" text @click="dialog = false">Close</v-btn>
            <v-spacer/>
            <v-btn color="primary" dark @click="dialog = false" disabled>Make Offer</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
</div>
</template>
<script>
import firebase from 'firebase'
import Functions from '../Functions'
import moment from 'moment'
import Data from '../Data'
export default {
    name: 'Listing',
    props: ['item', 'index'],
    data: () => ({
        price: '',
        area: '',
        agency: {id: '', name: '', photoURL: ''},
        agent: {id: '', name: '', photoURL: ''},
        address: [],
        timestamp: '',
        status: 'Active',
        listingType: 'Residential',
        propertyType: 'Plot',
        features: { Corner: false, DP: false, FacingPark: false, Pole: false, Posession: true, RoadWidth: "0" },
        loading: true,
        dialog: false,
        plots: [],
        otherSellers: [],
        fullscreen: false,
        error: false
    }),
    created() {
        firebase.firestore().collection('ActiveListings').doc(this.item.id)
        .get()
        .then((doc) => {
            if (doc.exists) {
                this.price = Functions.PriceInDefaultUnit(doc.data().price)
                this.area = Functions.AreaInDefaultUnit(doc.data().size)
                this.agency.id = doc.data().agencyID
                this.agency.name = doc.data().agency.name
                this.agency.photoURL = Functions.GetImageURL(doc.data().agency.photoURL)
                this.agent.id = doc.data().agentID
                this.agent.name = doc.data().agent.name
                this.agent.photoURL = Functions.GetImageURL(doc.data().agent.photoURL),
                this.address.push(doc.data().address)
                this.timestamp = moment.unix(doc.data().timestamp.seconds).utc().format("ddd Do MMM")
                this.features = doc.data().features
                this.propertyType = doc.data().listingType
                this.plots = doc.data().plots
                this.plots.forEach(plot => {
                    this.$store.dispatch('AddMarker', {position: {lat: plot.latitude, lng: plot.longitude}})
                })
                this.listingType = Functions.GetBlockType(this.address[0].block)
            } else {
                this.error = true
            }
            this.loading = false
        }).catch((err) => {
            this.error = true
            console.log(err)
        })
    },
    mounted () {
        this.onResize()
        window.addEventListener('resize', this.onResize, { passive: true })
    },
    methods: {
        onResize () {
            this.fullscreen = window.innerWidth < 600
        },
        tapped() {
            let user = firebase.auth().currentUser
            if (user) {
                this.dialog = true
                this.getOtherSellers()
            } else {
                this.$store.dispatch('SnackBar', Data.NotSignedInSnackBar)
            }
        },
        getOtherSellers() {
            let plotIDs = []
            this.plots.forEach(plot => {
                plotIDs.push(`${this.address[0].country}-${this.address[0].state}-${this.address[0].city}-${this.address[0].society}-${this.address[0].phase}-${this.address[0].block}-Plot-${plot.plotlabel}`)
            })
            let data = []
            firebase.firestore().collection('Search')
            .orderBy('timestamp', 'desc')
            .where('search', 'array-contains-any', plotIDs)
            .where('status', '==', 'Active')
            .get()
            .then((docs) => {
                docs.forEach(doc => {
                    if (doc.id != this.item.id) {
                        let m = doc.data().marker != null ? {lat: doc.data().marker.latitude, lng: doc.data().marker.longitude} : null
                        data.push({
                            id: doc.id, 
                            data: doc.data(), 
                            index: data.length + 1, 
                            marker: m
                        })
                    }
                })
                if (data.length > 0) {
                    this.otherSellers = data
                }
            }).catch((err) => {
                console.log(err)
            })

        },
        showAgencyProfile() {
            this.$router.push(`/aprofile/Agency:${this.agency.id}`)
        },
        showAgentProfile() {
            this.$router.push(`/profile/${this.agent.id}`)            
        },
        moreListings() {
            let addresses = []
            addresses.push(`PK-PJ-${this.address[0].city}-${this.address[0].society}-${this.address[0].phase}-${this.address[0].block}`)
            let data = []
            firebase.firestore().collection('Search')
            .orderBy('timestamp', 'desc')
            .where('search', 'array-contains-any', addresses)
            .where('status', '==', 'Active')
            .get()
            .then((docs) => {
                docs.forEach(doc => {
                    let m = doc.data().marker != null ? {lat: doc.data().marker.latitude, lng: doc.data().marker.longitude} : null
                    data.push({
                        id: doc.id, 
                        data: doc.data(), 
                        index: data.length + 1, 
                        marker: m
                    })
                })
                if (data.length > 0) {
                    this.$store.dispatch('ClearMarkers')
                    this.$store.dispatch('Listings', data)
                    this.dialog = false
                    if (this.$router.currentRoute.fullPath != '/searchresults') {
                        this.$router.push('/searchresults')
                    }
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    }
}
</script>