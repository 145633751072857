<template>
    <div v-if="user.userType == 'Buyer' && user.uid != ''">
        <v-btn color="amber" rounded @click="clicked">Start Selling</v-btn>
    </div>
    <div v-else-if="user.userType == 'Seller' || user.userType == 'Agency Owner'">
        <v-btn color="amber" rounded @click="clicked">Sell</v-btn>
    </div>
</template>
<script>
export default {
    name: 'SellerBtn',
    data: () => ({

    }),
    methods: {
        clicked() {
            this.$emit('seller')
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    }
}
</script>