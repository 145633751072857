<template>
<div>
<v-toolbar class="primary" dark>
    <v-container>
        <v-row>
            <v-toolbar-title class="mt-3">Login</v-toolbar-title>
        </v-row>
    </v-container>
</v-toolbar>
<v-progress-linear v-if="loading" class="amber" indeterminate></v-progress-linear>
<v-container class="fill-height">
    <v-row justify-center align-center>
        <v-col md="6" sm="12" class="pa-12">
            <v-img src="img/undraw_Login_re_4vu2.svg"/>
        </v-col>
        <v-col md="6" sm="12" class="pt-12">
            <div class="primary--text"><b>Verify your phone number</b></div>
            <div class="primary--text mb-5"><b><span class="display-1"><b>Amaraat</b></span> will send an SMS message (carrier charges may apply) to verify your phone number.</b></div>
            <v-row>
                <v-col cols="6">
                    <v-select :items="countries" label="Country Code" item-text="country" item-value="code" v-model="countryCode" outlined hide-details dense></v-select>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="phone" outlined label="Phone Number" :disabled="step > 1" hide-details dense :prefix="prefix"></v-text-field>
                </v-col>
            </v-row>
            <v-text-field v-model="code" outlined label="code" v-if="step > 1" hide-details dense class="mt-3"></v-text-field>
            <v-btn id="signInBtn" class="primary mt-5" rounded @click="submit" v-if="step < 2">Sign In</v-btn>
            <v-btn @click="confirm" class="primary mt-5" rounded v-if="step > 1">Verify</v-btn>
        </v-col>
    </v-row>
</v-container>
</div>
</template>
<script>
import firebase from 'firebase'
import router from '../router'
import Data from '../Data'
export default {
    name: 'Login',
    data: () => ({
        step: 1,
        phone: '',
        code: '',
        countryCode: '92',
        countries: [],
        prefix: '+92',
        loading: false
    }),
    created() {
        this.countries = Data.Countries
    },
    mounted() {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('signInBtn', {
            'size': 'invisible', 'callback': function(response) { submit() }
        })
    },
    watch: {
        countryCode(newVal, oldVal) {
            this.prefix = '+' + newVal
        }
    },
    methods: {
        submit() {
            let p = this.prefix + this.phone
            let appVerifier = window.recaptchaVerifier
            firebase.auth().signInWithPhoneNumber(p, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult
                this.step = 2
            }).catch((error) => {
                window.recaptchaVerifier.render().then((widgetId) => {
                    grecaptcha.reset(widgetId)
                })
            })
        },
        confirm() {
            confirmationResult.confirm(this.code).then((result) => {
                let user = result.user
                router.back()
            }).catch((error) => {
                console.log(error)
            })
        }
    }
}
</script>