<template>
<div>
<v-toolbar class="primary" dark>
    <v-container>
        <v-row>
            <v-toolbar-title class="mt-3">Find Agency/Agent</v-toolbar-title>
            <v-text-field v-model="search" class="mt-2 ml-12" dark label="Search ..." rounded dense hide-details solo></v-text-field>            
        </v-row>
    </v-container>
</v-toolbar>
<v-progress-linear v-if="loading" class="amber" indeterminate></v-progress-linear>
<v-container class="maxWidth">
    <v-list two-line>
        <v-list-item v-for="item in sortedAgencies" :key="item.id" class="grey lighten-3 my-1" link @click="agencyClicked(item)">
        <v-list-item-avatar>
            <v-img :src="item.profileurl"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>{{item.name}}</v-list-item-title>
            <v-list-item-subtitle>{{item.address}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
        </v-list-item>
    </v-list>
</v-container>
</div>
</template>
<script>
import firebase from 'firebase'
import Functions from '../Functions'
export default {
    name: 'FindAgencyAgent',
    data: () => ({
        loading: false,
        agencies: [],
        search: '',
    }),
    mounted() {
        this.getAgencies()
    },
    methods: {
        agencyClicked(agency) {
            this.$router.push(`/aprofile/Agency:${agency.id}`)
        },
        getAgencies() {
            let data = []
            this.loading = true
            firebase.firestore().collection('Agencies')
            .orderBy('name')
            .where('status', '==', 'Active').get()
            .then((docs) => {
                docs.forEach(doc => {
                    data.push({
                        id: doc.id,
                        name: doc.data().name,
                        profileurl: Functions.GetImageURL(doc.data().profileurl),
                        address: doc.data().address != null ? doc.data().address: ''
                    })
                })
                this.agencies = data
                this.loading = false
            }).catch((err) => {
                console.log(err)
            })
        }
    },
    computed: {
        sortedAgencies() {
            let results = []
            if (this.search.trim() == '') {
                return this.agencies
            } else {
                results = this.agencies.filter((a) => {return a.name.toLowerCase().startsWith(this.search.toLowerCase())})
                return results
            }
        }
    }
}
</script>