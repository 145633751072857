<template>
<div>
    <v-row>
        <v-col cols="4" class="text-left mt-2">{{title}}</v-col>
        <v-col cols="4">
            <v-text-field :label="label1" outlined dense hide-details v-model="value1" rounded/>
        </v-col>
        <v-col cols="4">
            <v-text-field :label="label2" outlined dense hide-details v-model="value2" rounded/>
        </v-col>
    </v-row>
</div>
</template>
<script>
export default {
    name: 'InputPair',
    props: ['title', 'label1', 'label2', 'type'],
    data: () => ({
        value1: '',
        value2: ''
    }),
    watch: {
        value1(newVal, oldVal) {
            this.$emit("pair", this.type, newVal, this.value2)
        },
        value2(newVal, oldVal) {
            this.$emit("pair", this.type, this.value1, newVal)
        },
        uClear(newVal, oldVal) {
            if (newVal) {
                this.value1 = ''
                this.value2 = ''
            }
        }
    },
    computed: {
        uClear() {
            return this.$store.state.uClear
        }
    }
}
</script>