<template>
    <v-container>
        <v-breadcrumbs :items="bitems"></v-breadcrumbs>
        <v-timeline>
            <v-timeline-item v-for="(t,i) in Items" :key="i" large>
            <template v-slot:icon>
                <v-avatar><img :src="t.profile"></v-avatar>
            </template>
            <template v-slot:opposite>
                <span>{{t.name}}</span>
            </template>
            <v-card class="elevation-2">
                <v-card-title class="headline">{{t.title}}</v-card-title>
                <v-card-text>{{t.text}}</v-card-text>
            </v-card>
            </v-timeline-item>
        </v-timeline>
    </v-container>
</template>
<script>
export default {
    data: () => ({
        bitems: [{text: 'Home', disabled: false, href: '/'}, {text: 'Testimonials', disabled: true, href: '/testimonials'}],
        Items: [
            {name: 'Capt (Retd) Shahnawaz Yaqub Bhatti', title: 'CEO imlaak real estate', text: 'As a real estate consultant I have found this app to be a blessing in disguise, I have been waiting for years for some thing like this which makes an agents job easy and unlike other portals requires zero technical know how to use. I love the digital maps and driving directions which make it so much easier to go around the phases and looking at all those plots on your own. I believe this app will change plot buying and selling forever. I recommend it for all agents and investors and those who wish to buy or sell the plot to download it immediately.', profile: 'https://amaraat.com/wp-content/uploads/2017/05/13179197_10208231765125407_2287221753627836881_n-150x150.jpg'},
            {name: 'Hassan Farooq Nizami', title: 'CEO Siban Associates', text: 'This is a wonderful app, very innovative with easy interface. I love the offer system as that will make it so much more easy to communicate with the sellers agent. Wishlist is another wonderful feature which I am very excited to explore . I will recommend that Amaraat is a must have app for everyone who wants to buy or sell there plot.', profile: 'https://amaraat.com/wp-content/uploads/2017/05/hassan-150x150.jpg'},
        ]
    })
}
</script>
