<template>
<div>
    <v-row>
        <v-col :lg="lg" :md="md" :sm="sm" :xs="xs">
            <v-select :dark="dark" v-model="SelectedListingType" :items="ListingTypes" label="Listing Type" outlined dense hide-details rounded />                        
        </v-col>
        <v-col :lg="lg" :md="md" :sm="sm" :xs="xs">
            <v-select :dark="dark" v-model="SelectedType" :items="Types" label="Type" outlined dense hide-details rounded />
        </v-col>
        <v-col :lg="lg" :md="md" :sm="sm" :xs="xs">
            <v-select :dark="dark" v-model="SelectedCity" :items="Cities" label="City" outlined dense hide-details item-text="CityName" item-value="CityCode" rounded />
        </v-col>
        <v-col :lg="lg" :md="md" :sm="sm" :xs="xs">
            <v-select :dark="dark" v-model="SelectedSociety" :items="Societies" label="Society" outlined dense hide-details item-text="SocietyName" item-value="SocietyCode" rounded />
        </v-col>
        <v-col :lg="lg" :md="md" :sm="sm" :xs="xs">
            <v-select :dark="dark" v-model="SelectedPhase" :items="Phases" label="Phase" outlined dense hide-details item-text="PhaseName" item-value="PhaseDBCode" rounded />
        </v-col>
        <v-col :lg="lg" :md="md" :sm="sm" :xs="xs" v-if="type == 'single'">
            <v-select :dark="dark" v-model="SelectedBlock" :items="Blocks" label="Block" outlined dense hide-details item-text="BlockName" item-value="BlockDBCode" rounded />
        </v-col>
        <v-col :lg="lg" :md="md" :sm="sm" :xs="xs" v-else>
            <v-autocomplete :dark="dark" multiple v-model="SelectedBlocks" :items="Blocks" label="Block(s)" outlined dense hide-details item-text="BlockName" item-value="BlockDBCode" rounded />
        </v-col>
    </v-row>
</div>
</template>
<script>
import Data from '../Data'
import Functions from '../Functions'
export default {
    name: 'Location',
    props: ['type', 'lg', 'md', 'sm', 'xs', 'dark'],
    data: () => ({
        ListingTypes: Data.ListingTypes,
        SelectedListingType: 'Plot',
        Types: [],
        SelectedType: "All",
        Cities: Data.Cities,
        SelectedCity: 'LHE',
        Societies: [],
        SelectedSociety: '',
        Phases: [],
        SelectedPhase: '',
        Blocks: [],
        SelectedBlock: '',
        SelectedBlocks: []
    }),
    mounted () {
        this.Societies = Functions.GetSocieties(this.SelectedCity)
        if (this.type == 'single') {
            this.Types = Data.Types.slice(1, 3)
            this.SelectedType = 'Residential'
        } else {
            this.Types = Data.Types
        }
    },
    watch: {
        SelectedCity(newVal, oldVal) {
            this.Societies = Functions.GetSocieties(newVal)
        },
        SelectedSociety(newVal, oldVal) {
            this.Phases = Functions.GetPhases(newVal)
        },
        SelectedPhase(newVal, oldVal) {
            this.Blocks = Functions.GetBlocks(newVal, this.SelectedType)
        },
        SelectedBlock(newVal, oldVal) {
            this.sendAddress()
        },
        SelectedBlocks(newVal, oldVal) {
            this.sendAddress()
        },
        SelectedListingType(newVal, oldVal) {
            this.sendAddress()
        },
        SelectedType(newVal, oldVal) {
            this.sendAddress()
        }
    },
    methods: {
        sendAddress() {
            let address = []
            if (this.type == 'single') {
                address.push(`PK-PJ-${this.SelectedCity}-${this.SelectedSociety}-${this.SelectedPhase}-${this.SelectedBlock}`)
                this.$emit("address", address, this.SelectedListingType, this.SelectedType)
            } else {
                this.SelectedBlocks.forEach(block => {
                    address.push(`PK-PJ-${this.SelectedCity}-${this.SelectedSociety}-${this.SelectedPhase}-${block}`)
                })
                this.$emit("address", address, this.SelectedListingType, this.SelectedType)
            }
        }
    }

}
</script>