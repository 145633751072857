<template>
<div>
    <div v-if="loading">
        <span>Loading ...</span>
    </div>
    <div v-else>
        <div v-if="show == 'chip'">
            <v-chip class="ma-2" color="green" v-if="status == 'Sold'" text-color="white"><v-avatar left class="green darken-4">{{n}}</v-avatar>{{status}}</v-chip>
            <v-chip class="ma-2" color="primary" v-else-if="status == 'Active'" text-color="white"><v-avatar left class="primary darken-4">{{n}}</v-avatar>{{status}}</v-chip>
        </div>
        <div v-else>
            <v-card class="elevation-0" outlined @click="">
                <v-card-text>
                    <div class="display-1">{{n}}</div>
                    <div>{{status}}</div>
                </v-card-text>
            </v-card>            
        </div>
    </div>
</div>
</template>
<script>
import firebase from 'firebase'
export default {
    name: 'Stat',
    props: ['show', 'type', 'id', 'status'],
    data: () => ({
        n: 0,
        loading: true
    }),
    mounted() {
        let count = 0
        firebase.firestore().collection('Search')
        .orderBy('timestamp', 'desc')
        .where('search', 'array-contains', `${this.type}:${this.id}`)
        .where('status', '==', this.status)
        .get()
        .then((docs) => {
            docs.forEach(doc => {
                count++
            })
            this.n = count
            this.loading = false
        }).catch((err) => {
            console.log(err)
        })

    }
}
</script>