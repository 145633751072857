<template>
<div>
    <v-toolbar class="primary" dark>
        <v-container>
            <v-row>
                <v-toolbar-title class="mt-3">Public Requirements</v-toolbar-title>
            </v-row>
        </v-container>
    </v-toolbar>
    <v-progress-linear v-if="loading" class="amber" indeterminate></v-progress-linear>
    <v-container class="maxWidth">
        <Requirement v-for="r in requirements" :key="r.id" type='public' :item='r'/>
    </v-container>
</div>
</template>
<script>
import firebase from 'firebase'
export default {
    name: 'SearchRequirementResults',
    data: () => ({
        loading: false,
    }),
    mounted() {
    },
    methods: {
        menuClicked(path) {
            if (path == 'mine') {

            } else {
                this.$router.push(path)
            }
        },
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        requirements() {
            return this.$store.state.requirements
        }
    }
}
</script>