<template>
<!--
<v-row>
    <v-col md="2" class="text-left">
        <img :src="agency.photoURL" class="borderProfile">
    </v-col>
    <v-col md="8" class="text-center">
       <div class="agencyName font-weight-black mt-2">{{agency.name}}</div>
       <div class="">{{agent.name}}</div>
    </v-col>
    <v-col md="2" class="text-right">
        <img :src="agent.photoURL" class="borderProfile">
    </v-col>
</v-row>
-->
<div class="flex-container">
    <div class="picsDiv">
        <img :src="agency.photoURL" class="borderProfile">
        <v-avatar :size="$vuetify.breakpoint.mobile ? 20 : 40" class="agentProfile">
            <img :src="agent.photoURL">
        </v-avatar>
    </div>
    <div class="namesDiv text-truncate">
        <div class="font-weight-black">{{agency.name}}</div>
        <div class="text-left">{{agent.name}}</div>
    </div>
</div>
</template>
<script>
export default {
    name: 'AgencyAgent',
    props: ['agency', 'agent'],
    data: () => ({

    })
}
</script>
<style>
.agentProfile {
    border: 3px solid var(--primary);
    position: relative;
    left: -20px;
}
.picsDiv {
    display: inline;
}

.flex-container {
    display: flex;
}
.agencyName {
    position: relative;
    left: -5px;
}
</style>