<template>
<div>
    <v-progress-linear v-if="loading" class="amber" indeterminate></v-progress-linear>
    <v-container>
        <v-row class="py-3">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="primary" rounded v-bind="attrs" v-on="on"><v-icon>mdi-calendar</v-icon> {{selectedDate}}</v-btn>
                </template>
                <v-date-picker v-model="date" @input="menu = false" no-title scrollable></v-date-picker>
            </v-menu>
        </v-row>
        <GmapMap :center="mapCenter" :zoom="12" map-type-id="satellite" class="map" style="height: 240px;">
            <GmapMarker
                :key="index"
                v-for="(m, index) in MapMarkers"
                :position="m.position"
                :clickable="true"
                :draggable="false"
                @click="center=m.position"/>
        </GmapMap>
        <v-row>
            <v-col md="4" sm="12" v-for="L in listings" :key="L.id">
                <NewListingCard :item="L" :index="L.index"/>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>
<script>
import firebase from 'firebase'
import moment from 'moment'
export default {
    name: 'Buy',
    data: () => ({
        date: null,//new Date().toISOString().substr(0, 10),
        menu: false,
        loading: true,
        listType: 'active',
        markers: [],
        listings: [],
        mapCenter: {lat:31.595228, lng:74.302232},
        selectedDate: moment().format('dddd, Do MMM, YYYY'),
        agencies: []
    }),
    mounted() {
        this.$store.dispatch('ClearMarkers', [])
        this.getFirst()
    },
    watch: {
        date(newVal, oldVal) {
            this.listings = []
            this.agencies = []
            let date1 = new Date(newVal)
            date1.setDate(date1.getDate() + 1)
            this.getTheRest(date1)
        }
    },
    methods: {
        getFirst() {
            let dated = 0
            firebase.firestore().collection('Search')
            .orderBy('timestamp', 'desc')
            .limit(1)
            .where('status', '==', 'Active')
            .get()
            .then((docs) => {
                docs.forEach(doc => {
                    dated = doc.data().timestamp.seconds*1000
                })
                this.getTheRest(dated)
            }).catch((err) => {
                console.log(err)
            })
        },
        getTheRest(date) {
            this.loading = true
            let marker = null
            let data = []
            let agenciesData = []
            let date1 = new Date(date)
            this.selectedDate = moment(date1).format('dddd, Do MMM, YYYY')
            let today = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate())
            let timestamp = firebase.firestore.Timestamp.fromDate(today)

            let nextDay = moment(date1)
            nextDay = nextDay.add(1, 'days')
            let tomorrow = new Date(nextDay.year(), nextDay.month(), nextDay.date())
            let ntimestamp = firebase.firestore.Timestamp.fromDate(tomorrow)
            firebase.firestore().collection('Search')
            .orderBy('timestamp', 'desc')
            .where('status', '==', 'Active')
            .where('timestamp', '>=', timestamp)
            .where('timestamp', '<', ntimestamp)
            .get()
            .then((docs) => {
                docs.forEach(doc => {
                    let m = doc.data().marker != null ? {lat: doc.data().marker.latitude, lng: doc.data().marker.longitude} : null
                    if (marker == null) {
                        marker = m
                    }
                    data.push({
                        id: doc.id, 
                        data: doc.data(), 
                        index: data.length + 1, 
                        marker: m
                    })
                    let s = doc.data().search
                    for (let k in s) {
                        if (s[k].startsWith('Agency')) {
                            let a = s[k].replace('Agency:', '')
                            let index = agenciesData.findIndex(ag => ag.id == a)
                            if (index < 0) {
                                agenciesData.push({id: a})
                            }
                            break
                        }
                    }
                })
                this.$store.dispatch('ClearMarkers')
                this.listings = data
                this.mapCenter = marker
                this.agencies = agenciesData
                this.loading = false
            }).catch((err) => {
                console.log(err)
            })
        },
    },
    computed: {
        MapMarkers() {
            return this.$store.state.markers
        }
    }
}
</script>