<template>
<div class="my-2">
    <v-skeleton-loader type="list-item-avatar-three-line" class="mx-auto" v-if="loading"></v-skeleton-loader>
    <v-card v-else @click="showProfile">
        <div class="d-flex flex-no-wrap justify-space-between">
            <v-avatar class="ma-3" size="100">
                <v-img :src="photoURL"></v-img>
            </v-avatar>
            <div class="text-truncate">
                <v-card-title class="headline">{{title}}</v-card-title>
                <v-card-subtitle>{{address}}</v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <Stat :id="agency.id" :type="'Agency'" :status="'Active'" :show="'chip'"/>
                        <Stat :id="agency.id" :type="'Agency'" :status="'Sold'" :show="'chip'"/>
                    </v-row>
                </v-card-text>
            </div>
        </div>
    </v-card>
</div>
</template>
<script>
import firebase from 'firebase'
import Functions from '../Functions'
export default {
    name: 'AgencyCard',
    props: ['agency'],
    data: () => ({
        loading: true,
        title: '',
        address: '',
        photoURL: ''
    }),
    mounted() {
        firebase.firestore().collection('Agencies').doc(this.agency.id).get()
        .then((doc) => {
            this.title = doc.data().name
            this.address = doc.data().address
            this.photoURL = Functions.GetImageURL(doc.data().profileurl)
            this.loading = false
        }).catch((err) => {
            console.log(err)
        })
    },
    methods: {
        showProfile() {
            this.$router.push('/aprofile/Agency:' + this.agency.id)
        }
    }
}
</script>