<template>
<div>
<v-toolbar class="primary" dark>
    <v-container>
        <v-row>
            <v-toolbar-title class="mt-3">Add Listing</v-toolbar-title>
        </v-row>
    </v-container>
</v-toolbar>
<v-progress-linear v-if="loading" class="amber" indeterminate></v-progress-linear>
<v-container class="maxWidth">
    <v-card>
        <v-toolbar dense class="elevation-0 grey lighten-3">
            <v-toolbar-title>Location</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <Location :type="'single'" :lg="'6'" :md="'6'" :sm="'12'" v-on:address="address"/>
            <div v-if="listingType == 'Plot'">
                <div class="primary--text" v-if="!showPlots">{{plotsStatus}}</div>
                <v-autocomplete v-else v-model="selectedPlots" :items="plots" label="Selected Plots" multiple chips outlined hide-details item-text="label" item-value="id"></v-autocomplete>
            </div>
            <div v-else>
                <v-select v-model="selectedPossession" :items="possessionTypes" label="Status" outlined dense hide-details />
            </div>
        </v-card-text>
        <v-toolbar dense class="elevation-0 grey lighten-3">
            <v-toolbar-title>Area &amp; Price</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <InputPair :title="'Area'" :label1="'Kanal'" :label2="'Marla'" :type="'area'" v-on:pair="pair" class="px-3"/>
            <InputPair :title="'Price'" :label1="'Crore'" :label2="'Lac'" :type="'price'" v-on:pair="pair" class="px-3"/>
        </v-card-text>
        <div v-if="listingType == 'Plot'">
            <v-toolbar dense class="elevation-0 grey lighten-3">
                <v-toolbar-title>Features</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <FeaturesInput :rw='true' v-on:featureUpdate="featureUpdate"/>
            </v-card-text>
        </div>
        <v-row class="pa-6">
            <v-spacer/>
            <v-btn class="primary" rounded dark large @click="savePlot" :loading="loading">Post</v-btn>
        </v-row>
    </v-card>
</v-container>
</div>
</template>
<script>
import Data from '../Data'
import Functions from '../Functions'
import firebase from 'firebase'
export default {
    name: 'NewListing',
    data: () => ({
        plots: [],
        selectedPlots: [],
        selectedAddress: [],
        loading: false, 
        area: 0,
        price: 0,
        features: {},
        listingType: 'Plot',
        propertyType: 'Residential',
        plotsStatus: 'No Plot Selected',
        showPlots: false,
        possessionTypes: Data.PossessionTypes,
        selectedPossession: 'Allocation'
    }),
    mounted () {
    },
    methods: {
        address(address, listingType, propertyType) {
            this.selectedPlots = []
            this.plots = []
            this.selectedAddress = address
            this.listingType = listingType
            this.propertyType = propertyType
            this.getPlots()
        },
        pair(type, value1, value2) {
            if (type == 'area') {
                this.area = Functions.CombineArea(value1, value2)
            } else if (type == 'price') {
                this.price = Functions.CombinePrice(value1, value2)
            }
        },
        featureUpdate(data) {
            this.features = data
        },
        getPlots() {
            if (Functions.CheckAddress(this.selectedAddress[0])) {
                let address = this.selectedAddress[0].replace(/-/g, '')
                this.loading = true
                this.plotsStatus = 'Loading Plots ...'
                let data = []
                firebase.firestore().collection(`NewPlots/${address}/Plots`).orderBy('plotNumber').get()
                .then((docs) => {
                    docs.forEach((doc) => {
                        data.push({
                            id: doc.id,
                            label: doc.data().plotLabel
                        })
                    })
                    this.loading = false
                    this.plots = data
                    this.showPlots = true
                }).catch((err) => {
                    console.log(err)
                })
            }
        },
        savePlot() {
            if (this.listingType == 'Plot' && this.selectedPlots.length == 0) {
                alert("No Plot Selected")
            } else if (isNaN(this.area)) {
                alert("Invalid size")
            } else if (isNaN(this.price)) {
                alert("Invalid price")
            } else if (Functions.CheckAddress(this.selectedAddress[0]) == false) {
                alert("Invalid Address")
            } else {
                let user = firebase.auth().currentUser
                if (user && !this.loading) {
                    var pls = '';
                    this.selectedPlots.forEach((element) => {
                        if (pls != '') {
                            pls += '-'
                        }
                        pls += element
                    })
                    let address = this.selectedAddress[0].split('-')
                    let p = {}
                    let add = this.selectedAddress[0].replace(/-/g, '')
                    if (this.listingType == 'Plot') {
                        p = {
                            'user': user.uid,
                            'address': {
                                'address': add,
                                'country': 'PK',
                                'state': 'PJ',
                                'city': address[2],
                                'society': address[3],
                                'phase': address[4],
                                'block': address[5]
                            },
                            'features': {
                                'Corner': this.features.corner,
                                'DP': this.features.dp,
                                'FacingPark': this.features.facingPark,
                                'Pole': this.features.pole,
                                'Posession': this.features.posession,
                                'RoadWidth': this.features.roadWidth
                            },
                            'listingType': this.listingType,
                            'propertyType': this.propertyType,
                            'size': this.area,
                            'price': this.price,
                            'purpose': 'Sale',
                            'plots': pls
                        }
                    } else {
                        p = {
                            'user': user.uid,
                            'address': {
                                'address': add,
                                'country': 'PK',
                                'state': 'PJ',
                                'city': address[2],
                                'society': address[3],
                                'phase': address[4],
                                'block': address[5],
                                'possession': this.selectedPossession
                            },
                            'listingType': this.listingType,
                            'propertyType': this.propertyType,
                            'size': this.area,
                            'price': this.price,
                            'purpose': 'Sale'
                        }
                    }
                    this.loading = true
                    firebase.firestore().collection('PendingListings').add(p)
                    .then((ref) => {
                        this.loading = false
                        this.$router.back()
                    }).catch((err) => {
                        this.loading = false
                        this.$store.dispatch('SnackBar', {status: true, msgText: err, color: 'red darken-4', timeout: -1, closeAble: true})
                    })
                }
            }
        }
    }
}
</script>